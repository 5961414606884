import { useEffect, useState } from 'react';
import { useAuthContext } from '@state/auth';
import { saveUpdateInfoStatus } from '@util/api';
import Joyride from 'react-joyride';
import Tooltip from '@views/WalkThrough/Tooltip';

const JobPostingsUpdate = () => {
  const {
    user,
    showJpUpdateInfo,
    setShowJpUpdateInfo,
    showIhUpdateInfo,
    setShowIhUpdateInfo,
  } = useAuthContext();

  const [runUpdateInfo, setRunUpdateInfo] = useState(
    showJpUpdateInfo || showIhUpdateInfo
  );

  useEffect(() => {
    setRunUpdateInfo(showJpUpdateInfo || showIhUpdateInfo);
  }, [showJpUpdateInfo, showIhUpdateInfo]);

  const LegendRegular = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
  };

  const updateInfoSteps = [
    ...(showJpUpdateInfo
      ? [
          {
            target: '#jp-module-card',
            disableBeacon: true,
            placement: 'auto',
            title: 'Update',
            content: (
              <div style={{ maxWidth: '280px' }}>
                <p style={LegendRegular}>
                  New dashboards and time filters have been added. Check it out
                  now!
                </p>
              </div>
            ),
          },
        ]
      : []),
    ...(showIhUpdateInfo
      ? [
          {
            target: '#insights-hub-card',
            disableBeacon: true,
            placement: 'auto',
            title: 'Update',
            content: (
              <div style={{ maxWidth: '280px' }}>
                <p style={LegendRegular}>
                  The Diligence module has a new name: stay tuned as new
                  insights get added!
                </p>
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleUpdatePopupClose = () => {
    if (showJpUpdateInfo) {
      setShowJpUpdateInfo(false);
      saveUpdateInfoStatus(user.email, { job_postings: false });
    }
    if (showIhUpdateInfo) {
      setShowIhUpdateInfo(false);
      saveUpdateInfoStatus(user.email, { insights_hub: false });
    }
  };

  return (
    <Joyride
      run={runUpdateInfo}
      steps={updateInfoSteps}
      continuous
      hideBackButton
      scrollToFirstStep
      styles={{
        spotlight: { borderRadius: 8 },
      }}
      floaterProps={{
        hideArrow: false,
        disableAnimation: true,
      }}
      callback={({ type }) => {
        if (type === 'tour:end') {
          handleUpdatePopupClose();
        }
      }}
      tooltipComponent={Tooltip}
    />
  );
};

export default JobPostingsUpdate;
