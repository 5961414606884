import { useStateContext } from '@state';
import { useAuthContext } from '@state/auth';
import PremiumModal from '@views/modals/PremiumModal';
import IncompleteDownloadsAlert from '@views/modals/IncompleteDownloadsAlert';
import { Modal } from '@components/modal';
import Icon from '@components/icon';
import { Button } from '@components/button';
import Text from '@components/text';

import styles from './modals.module.scss';

const Modals = () => {
  const { tokenHasExpired } = useAuthContext();
  const {
    showPremiumModal,
    setShowPremiumModal,
    showIncompleteDownloadsAlert,
    setShowIncompleteDownloadsAlert,
  } = useStateContext();
  return (
    <>
      {showIncompleteDownloadsAlert && (
        <IncompleteDownloadsAlert
          onClose={() => setShowIncompleteDownloadsAlert(false)}
        />
      )}
      {showPremiumModal && (
        <PremiumModal onClose={() => setShowPremiumModal(false)} />
      )}
      {tokenHasExpired && (
        <Modal
          className={styles.sessionExpiredModalContainer}
          contentClassName={styles.sessionExpiredModalContent}
          headerText={
            <div className={styles.sessionExpiredModalHeader}>
              <Icon
                icon="warning"
                viewBox="2 5 20 14"
                fill="none"
                size="xlarge"
              />
              <Text type="H5Semibold" style={{ marginTop: '20px' }}>
                Session Expired
              </Text>
            </div>
          }
          hasCloseButton={false}
          centered
          footerButtonPropsList={[]}
          maskStyle={{ background: 'rgba(0, 0, 0, 0.9)' }}
          isVisible={true}
          contentScrollable={false}
          contentComponent={
            <div className={styles.sessionExpiredModal}>
              <Text
                type="BodySmallRegular"
                style={{
                  margin: '20px 0',
                  color: 'var(--gray-600)',
                  textAlign: 'justify',
                }}
              >
                For security purposes, your session has expired. Please refresh
                your window to reconnect
              </Text>
              <Button
                type={'primary'}
                title="Refresh"
                onClick={() => {
                  location.reload();
                }}
                style={{ width: '100%', margin: 'auto 0' }}
              />
            </div>
          }
        />
      )}
    </>
  );
};

export default Modals;
