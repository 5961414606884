import { createContext, useContext, useState } from 'react';

const JobPostingsContext = createContext();
const useJobPostingsContext = () => useContext(JobPostingsContext);

function JobPostingsContextProvider({ children }) {
  const [companySelection, setCompanySelection] = useState([]);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([]);

  return (
    <JobPostingsContext.Provider
      value={{
        companySelection,
        setCompanySelection,
        cubeQueryFilters,
        setCubeQueryFilters,
      }}
    >
      {children}
    </JobPostingsContext.Provider>
  );
}

export { useJobPostingsContext, JobPostingsContextProvider };
