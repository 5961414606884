const availableCompanySearchFilterTerms = 
`getAvailableCompanySearchFilterTerms(name: $name) {
  industries {
    name
    count
  }
  currentCountProfile {
    min
    max
  }
}`

export default availableCompanySearchFilterTerms;