import PropTypes from 'prop-types';
import styles from './styles/premium.module.scss';

const PremiumLabel = ({ style }) => {
  return (
    <span className={styles.premiumLabel} style={style}>
      PREM
    </span>
  );
};

PremiumLabel.propTypes = {
  style: PropTypes.object,
};

export default PremiumLabel;
