import React, { useEffect, useState } from 'react';
import styles from './ProjectsModal.module.scss';
import classnames from 'classnames';
import { Input, InputErrorMessage } from '@components/input';
import Select from 'react-select';
import { validateEmail } from '@util/email';

function Fields({
  setDisabled,
  formFields,
  setFormFields,
  caseTypeOptions,
  caseTypeOptionsPayg,
  officeOptions,
  capabilityOptions,
  industriesOptions,
  isPaygUser,
}) {
  // State

  //   HandleChanges
  const handleChange = (event, type) => {
    let options, setSelection, defaultOption;
    switch (type) {
      case 'caseType':
        options = !isPaygUser ? caseTypeOptions : caseTypeOptionsPayg;
        setSelection = setFormFields;
        defaultOption = { ...formFields, caseType: options[0] };
        break;
      default:
        return; // or throw an error if needed
    }

    const selectedOption = options.find(option => option === event);
    setSelection(prevState =>
      selectedOption
        ? { ...prevState, [type]: selectedOption.key }
        : defaultOption
    );
  };
  const handleOfficeChange = selectedOptions => {
    setFormFields(prevState => ({
      ...prevState,
      office: selectedOptions,
      region: {
        key: selectedOptions.regionKey,
        label: selectedOptions.regionLabel,
      },
    }));
  };

  const handleCapabilityChange = selectedOptions => {
    setFormFields(prevState => ({
      ...prevState,
      capability: selectedOptions,
    }));
  };

  const handleIndustryChange = selectedOptions => {
    setFormFields(prevState => ({
      ...prevState,
      industry: [selectedOptions],
    }));
  };

  //   Conditions
  const [isCaseManagerInvalid, setIsCaseManagerInvalid] = useState(false);
  const [
    hasInteractedWithCaseManager,
    setHasInteractedWithCaseManager,
  ] = useState(false);

  const handleCaseManagerChange = e => {
    const inputValue = e.target.value;
    setIsCaseManagerInvalid(false);
    setFormFields(prevState => ({
      ...prevState,
      caseManager: inputValue,
    }));
    setIsCaseManagerInvalid(!validateEmail(inputValue));
  };

  const [isPartnerInvalid, setIsPartnerInvalid] = useState(false);
  const [hasInteractedWithPartner, setHasInteractedWithPartner] = useState(
    false
  );

  const handlePartnerChange = e => {
    const inputValue = e.target.value;
    setIsPartnerInvalid(false);

    const emails = inputValue.split(';').map(email => email.trim());

    setFormFields(prevState => ({
      ...prevState,
      partner: inputValue,
    }));
    setIsPartnerInvalid(!emails.every(email => validateEmail(email)));
  };

  //   Styles
  const multiSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f7f8fb', border: 0 }),
  };
  const singleSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f7f8fb', border: 0 }),
    option: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      color: '#667c8a',
      cursor: 'pointer',
    }),
  };

  useEffect(() => {
    for (const key in formFields) {
      if (
        formFields[key] === '' ||
        (Array.isArray(formFields[key]) && formFields[key].length === 0)
      ) {
        setDisabled(true);
        return;
      }
      if (isPartnerInvalid || isCaseManagerInvalid) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [formFields, isCaseManagerInvalid, isPartnerInvalid, setDisabled]);

  const projectOptions = !isPaygUser ? caseTypeOptions : caseTypeOptionsPayg;

  // JSX
  return (
    <div>
      {/* Case Type */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Project type*
        </div>
        <div className={styles.inputField}>
          <Select
            name="office"
            options={projectOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select project type"
            onChange={event => handleChange(event, 'caseType')}
            styles={singleSelectStyles}
            defaultValue={formFields?.caseType}
          />
        </div>
      </div>
      {/* Office & Region */}
      {!isPaygUser && (
        <div className={styles.fieldSet}>
          <div className={classnames(styles.font, styles.textLabel)}>
            Office & Region*
          </div>
          <div className={styles.inputField}>
            <Select
              name="office"
              options={officeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select office and region"
              onChange={handleOfficeChange}
              styles={singleSelectStyles}
              defaultValue={formFields?.office}
            />
          </div>
        </div>
      )}
      {/* Partner Email */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Partner Email
        </div>
        <Input
          placeholder="Type partner email here"
          className={styles.textInput}
          onChange={e => handlePartnerChange(e)}
          value={formFields.partner}
          onBlur={() => setHasInteractedWithPartner(true)}
        />

        {isPartnerInvalid && hasInteractedWithPartner && (
          <InputErrorMessage errorMessage='Partner must be one or multiple valid emails (allow ";" as separator).' />
        )}
      </div>
      {/* Project Manager Email */}
      <div className={styles.fieldSet}>
        <div className={classnames(styles.font, styles.textLabel)}>
          Project Manager Email
        </div>
        <Input
          placeholder="Type project manager email here"
          className={styles.textInput}
          onChange={e => handleCaseManagerChange(e)}
          value={formFields.caseManager}
          onBlur={() => setHasInteractedWithCaseManager(true)}
        />
        {isCaseManagerInvalid && hasInteractedWithCaseManager && (
          <InputErrorMessage errorMessage="Project Manager must be a valid email." />
        )}
      </div>
      {/* Capability */}
      {!isPaygUser && (
        <div className={styles.fieldSet}>
          <div className={classnames(styles.font, styles.textLabel)}>
            Capability*
          </div>
          <div className={styles.inputField}>
            <Select
              isMulti
              name="colors"
              options={capabilityOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select capability"
              closeMenuOnSelect={false}
              onChange={handleCapabilityChange}
              styles={multiSelectStyles}
              value={formFields?.capability}
            />
          </div>
        </div>
      )}
      {/* Industry */}
      {!isPaygUser && (
        <div className={styles.fieldSet}>
          <div className={classnames(styles.font, styles.textLabel)}>
            Industry*
          </div>
          <div className={styles.inputField}>
            <Select
              name="colors"
              options={industriesOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select industry"
              onChange={handleIndustryChange}
              styles={singleSelectStyles}
              value={formFields?.industry}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Fields;
