const searchCompaniesQuery = (query) => 
  `query SearchCompanies(
    $name: String
    $limit: Int
    $page: Int
    $industries: [String!]
    $currentCountProfile: IntRangeFilter
    $sortBy: String
    $order: SortingOrder
) {
    ${query ?? ''}

    findAllCompaniesPaginated(
        name: $name
        limit: $limit
        page: $page
        industries: $industries
        currentCountProfile: $currentCountProfile
        sortBy: $sortBy
        order: $order
    ) {
      nodes {
        name
        id
        uuid
        industry
        currentCountProfile
        historicalCountProfile
        linkedinUrl
        mainWebsite
      }
      totalCount
    }
}`

export default searchCompaniesQuery;