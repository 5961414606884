export const truncate = ({ string, length = 150, overflow = '...' }) =>
  string.length > length - overflow.length
    ? string.slice(0, length) + overflow
    : string;

export const capitalize = str => str.charAt(0).toUpperCase() + str.substring(1);

export const capitalizeAll = str =>
  str
    .split(' ')
    .map(capitalize)
    .join(' ');

export const longDateFormatter = dateString => {
  if (dateString) {
    const date = new Date(dateString);
    return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  }
};

export const shortDateFormatter = dateString => {
  if (dateString) {
    const date = new Date(dateString);
    const today = new Date();
    const isToday =
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear();
    return isToday
      ? `${date.getHours()}:${date.getMinutes()}`
      : `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  }
};

export const lowerCaseSearchQuery = queryString => {
  return queryString
    .split(' ')
    .map(val => {
      if (
        val.toLowerCase() === 'and' ||
        val.toLowerCase() === 'or' ||
        val.toLowerCase() === 'not'
      ) {
        return val.toUpperCase();
      }

      return val.toLowerCase();
    })
    .join(' ');
};

export const convertToDisplayValue = (value, displayAsPercent) => {
  if (!value && value !== 0) {
    return;
  }

  if (displayAsPercent) {
    return (value * 100).toFixed(0) + '%';
  } else {
    return value.toFixed(0);
  }
};

export const convertToNumericValue = (value, displayAsPercent) => {
  var parsedValue = null;

  if (displayAsPercent) {
    parsedValue = parseFloat(value.replace('%', '')) / 100;
  } else {
    parsedValue = parseFloat(value);
  }

  if (parsedValue !== null) {
    return parsedValue;
  }
};

export const isStringNumeric = str => {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

// sanitize new line, double quote and comma characters
// these characters make CSV parsing difficult
export function sanitizeForCSV(input) {
  return input
    .replace(/[\n\r]+/g, ' ')
    .replace(/["]+/g, '')
    .replace(/[,]+/g, ' ');
}
