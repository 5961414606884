import Text from '@components/text';

const LegendRegular = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
};

//Customized to show helpdesk mail based on user's mail
export const GetSteps = userHelpdeskMail => {
  return [
    {
      target: '#module-cards',
      disableBeacon: true,
      placement: 'auto',
      title: 'Getting started with Aura',
      content: (
        <div style={{ maxWidth: '570px' }}>
          <Text type="CaptionRegular">
            Aura is divided into core modules centered around talent data.
          </Text>
          <br />
          <Text type="CaptionMedium">With Insights Hub</Text>
          <Text type="CaptionRegular">
            Research and compare companies. Get deep insights into their growth,
            expertise and quality, quantity & skill matrix of talent.
          </Text>
          <br />
          <Text type="CaptionMedium">With Sourcing</Text>
          <Text type="CaptionRegular">
            Streamline sourcing by identifying high growth and high potential
            companies. Sort, filter and scan through our universe of 12M
            companies to find the winner of tomorrow.
          </Text>
          <br />
          <Text type="CaptionMedium">With Job Postings</Text>
          <Text type="CaptionRegular">
            Explore over 550M diverse job postings data from 2019 spanning
            various companies, roles, and skills, providing invaluable insights
            into the evolving landscape of employment trends and opportunities.
          </Text>
          <br />
          <Text type="CaptionRegular">
            For more details, refer to our&nbsp;
            <a href="https://auraintel.zendesk.com/hc/en-us" target="_blank">
              help center
            </a>
            .
          </Text>
        </div>
      ),
    },
    {
      target: '#user-icon',
      disableBeacon: true,
      placement: 'left',
      title: 'Getting started',
      content: (
        <div style={{ maxWidth: '280px' }}>
          <div
            style={{
              marginBottom: '16px',
            }}
          >
            <Text type="CaptionRegular" style={{ marginBottom: '12px' }}>
              Help Center
            </Text>
            <p style={LegendRegular}>
              Visit our{' '}
              <a href="https://auraintel.zendesk.com/hc/en-us" target="_blank">
                help center
              </a>{' '}
              to get started with analysis. We also curated a FAQ
              section to address most common topics from users.
            </p>
          </div>
          <div
            style={{
              marginBottom: '16px',
            }}
          >
            <Text type="CaptionRegular" style={{ marginBottom: '12px' }}>
              Contact us
            </Text>
            <p style={LegendRegular}>
              For additional questions and doubts, you can reach out to&nbsp;
              <a href={`mailto:` + userHelpdeskMail}>{userHelpdeskMail}</a>.
            </p>
          </div>
        </div>
      ),
    },
  ];
};

export const diligenceSteps = [
  {
    target: '#templates',
    disableBeacon: true,
    placement: 'right',
    title: 'Industry templates',
    content: (
      <div style={{ maxWidth: '400px' }}>
        <p style={LegendRegular}>Industry templates contain demo projects.</p>
        <br />
        <p style={LegendRegular}>
          For more details, refer to our&nbsp;
          <a href="https://auraintel.zendesk.com/hc/en-us" target="_blank">
            help center
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    target: 'button#new-project',
    disableBeacon: true,
    placement: 'left',
    title: 'Create an Insights Hub project',
    content: (
      <div style={{ maxWidth: '280px' }}>
        <p style={LegendRegular}>
          Choose project name, add companies and explore talent insights in
          minutes.
        </p>
      </div>
    ),
  },
];
