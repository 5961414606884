import { env } from '@common/env';
import { checkIsBainUser, checkIsAuraintelUser } from '@util/email';
import { range } from '@util/math';
import { getUserHelpdeskMail } from '../util/email';
import { currentEnv, isProd, isStage } from './platform';

const FeatureFlagConfigs = {
  Databox: {
    dev: true,
    prod: true,
    stage: true,
    canShow: user => checkIsAuraintelUser(user.email),
  },
  'Job Postings': {
    dev: true,
    prod: true,
    stage: true,
  },
  'Salary Benchmark': {
    dev: false,
    prod: false,
    stage: false,
  },
  'Talent Pool': {
    dev: false,
    prod: false,
    stage: false,
    canShow: user => checkIsBainUser(user.email),
  },
  uploadCSVRoleMapping: {
    dev: true,
    prod: true,
    stage: true,
  },
};

export const FeatureFlag = Object.freeze(
  Object.keys(FeatureFlagConfigs).reduce((enumObj, feature) => {
    enumObj[feature] = feature;
    return enumObj;
  }, {})
);

export const isFeatureEnabled = feature => {
  const config = FeatureFlagConfigs[feature];
  return config?.[currentEnv()] ?? false;
};

export const isModuleEnabled = ({ title }, user) => {
  const config = FeatureFlagConfigs[title];
  return (
    (config?.[currentEnv()] ?? true) &&
    (isProd() || isStage() ? config?.canShow?.(user) ?? true : true)
  );
};

export const PremiumFilters = [
  'latest_funding_round',
  'latest_funding_year',
  'latest_funding_raised_musd',
  'top_5_or_active_investors',
  'count_current_employees_top_cs',
  'count_current_employees_engg_top_cs',
  'annual_ex_faang_growth_last_6_months',
  'annual_ex_faang_growth_last_1_year',
  'annual_ex_faang_growth_last_2_years',
  'annual_ex_faang_growth_last_3_years',
  'top_cs_growth_last_6_months',
  'top_cs_growth_last_1_year',
  'top_cs_growth_last_2_years',
  'top_cs_growth_last_3_years',
];

export const PremiumLeadFinderPredefinedFormClauses = [
  'experience.company.industry',
];
export const PremiumLeadFinderFormCategories = ['skill'];
export const PremiumLeadFinderFormClauses = [
  'experience.$promotion_speed',
  'experience.$duration',
];

export const Subscriptions = [
  'Trial',
  'Pay-As-You-Go',
  'Basic',
  'Premium',
  'Enterprise',
];

export const CompanySearchConfig = {
  OWNER_ROLE_STRING: 'Owner',
  EDITOR_ROLE_STRING: 'Editor',
  DEFAULT_SORT_FIELD: 'count_current_employees',
  DEFAULT_SORT_ASC: false,
  DEFAULT_COLUMN_TOGGLE_VALUES_ARRAY: [
    'db_count',
    'main_company_name',
    'main_website',
    'linkedin_website',
    'main_type',
    'industry',
    'hq_country',
    'count_current_employees',
  ],
  DATA_MODEL: 'advanced_company_search',
  PAGE_SIZE: 25,
  METADATA_MODEL: 'advanced_company_search_metadata',
};

export const LazyLoadIcons = [
  'companies',
  'charts',
  'profile',
  'auraLogo',
  'job',
  'databox',
  'salary',
  'talentPool',
];

export const ProjectStatus = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
};

export const DiligenceOrganizationsCap = 20;
export const DataboxProfileLimit = 150000;

export const MONTH_DROPDOWN_ITEMS = [
  { key: '01', label: 'January' },
  { key: '02', label: 'February' },
  { key: '03', label: 'March' },
  { key: '04', label: 'April' },
  { key: '05', label: 'May' },
  { key: '06', label: 'June' },
  { key: '07', label: 'July' },
  { key: '08', label: 'August' },
  { key: '09', label: 'September' },
  { key: '10', label: 'October' },
  { key: '11', label: 'November' },
  { key: '12', label: 'December' },
];

export const YEAR_DROPDOWN_ITEMS = range(1900, new Date().getFullYear())
  .reverse()
  .map(year => ({
    key: String(year),
    label: String(year),
  }));

export const FieldTypes = {
  HIGH_CARDINALITY_DATA_TYPE: 'HIGH_CARDINALITY_CATEGORICAL',
  MEDIUM_CARDINALITY_DATA_TYPE: 'MEDIUM_CARDINALITY_CATEGORICAL',
  LOW_CARDINALITY_DATA_TYPE: 'LOW_CARDINALITY_CATEGORICAL',
  DATE_DATA_TYPE: 'DATE',
  BOOLEAN_DATA_TYPE: 'BOOLEAN',
  NUMBER_DATA_TYPE: 'NUMBER',
  PERCENT_DATA_TYPE: 'PERCENT',
  RANGE_DATA_TYPE: 'RANGE',
  DROP_DOWN: 'DROP_DOWN',
};

export const getCubeURL = () => String(env('REACT_APP_CUBEURL'));

// position world map to center (instead of 0,0) in the container with zoom level 2
export const worldMapCenter = [44.3, 4.9];

export const CorporateModule = {
  TalentLandscape: 'Talent landscape',
  TalentNeeds: 'Talent needs',
};

export const BGTaskType = {
  ETL: 'ETL',
  ETL_CLONE: 'ETL CLONE',
  EXPORT: 'EXPORT',
  ROLE_MAPPING: 'ROLE MAPPING',
};

export const DAGRun = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  RUNNING: 'running',
  STARTED: 'started',
  QUEUED: 'queued',
};

export const updateDashboardMsg = {
  BAIN_USER: `We’ve reorganized the Dashboards section to help you access insights on your selected companies more quickly. Should you have any questions or wish to share feedback, please don’t hesitate to contact us at bain@auraintel.com.`,
  NON_BAIN_USER: `We’ve reorganized the Dashboards section to help you access insights on your selected companies more quickly. Should you have any questions or wish to share feedback, please don’t hesitate to contact us at support@auraintel.com.`,
};
export const getUpdateDashboardMsg = email =>
  `We’ve reorganized the Dashboards section to help you access insights on your selected companies more quickly. Should you have any questions or wish to share feedback, please don’t hesitate to contact us at ${getUserHelpdeskMail(
    email
  )}`;

export const triallUserMsg = {
  download_msg: `Export feature is restricted in trial subscription. Please contact ${getUserHelpdeskMail()} for unrestricted access.`,
  project_msg: `Access of shared project is restricted in trial subscription. Please contact ${getUserHelpdeskMail()} for unrestricted access.`,
  share_project_msg: `Project sharing feature is restricted in trial subscription. Please contact ${getUserHelpdeskMail()} for unrestricted access.`,
};

export const CUBEJS_QUERY_LIMIT = 50000;
