import styles from './styles/AppLoading.module.scss';
import { BrandLogo } from '@components/brand';

function AppLoading({ redirect = false }) {
  return (
    <div className={styles.view}>
      <div>
        <div className={styles.logo}>
          <BrandLogo />
        </div>
        <small>
          {redirect ? 'Redirecting to Insights Hub...' : 'Loading...'}
        </small>
      </div>
    </div>
  );
}

export default AppLoading;
