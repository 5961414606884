import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import styles from './premiumModal.module.scss';
import { Modal } from '@components/modal';

// images
import PremiumModalIcon from '@assets/PremiumModalIcon.png';

export default function PremiumModal({ onClose }) {
  const leadFinderMatch = useRouteMatch('/lead-finder');

  const handleFindMore = () => {
    window.open('https://getaura.ai/', '_blank', 'noopener,noreferrer');
  };

  const message = leadFinderMatch?.isExact
    ? 'Our data engineers have been hard at work creating bespoke metrics and adding new data features to take your team’s capabilities to the next level.'
    : 'Our data engineers have been hard at work creating bespoke metrics and integrating new sources to take your team’s capabilities to the next level.';

  return (
    <div style={{ zIndex: 21 }}>
      <Modal
        className={styles.premiumModal}
        containerStyle={{ minWidth: '386px', maxWidth: '386px' }}
        headerText={
          <div className={styles.title}>
            <img
              src={PremiumModalIcon}
              className={styles.icon}
              alt="go premium"
            />
            <p>Supercharge your company searches</p>
            <p>
              with <span className={styles.premTxt}>Premium</span>
            </p>
          </div>
        }
        footerButtonPropsList={[
          {
            title: 'Find out more',
            type: 'primary',
            onClick: handleFindMore,
          },
          { title: 'Go Back', type: 'ghost', onClick: onClose },
        ]}
        footerClassName={styles.footer}
        contentClassName={styles.content}
        isVisible={true}
        onClose={onClose}
        contentComponent={
          <div className={styles.content}>
            <p>{message}</p>
            <p>To explore what Premium has to offer, click below</p>
          </div>
        }
      />
    </div>
  );
}

PremiumModal.propTypes = {
  onClose: PropTypes.func,
};
