import { env } from '@common/env';
import { getAccessToken } from '@state/auth';
import { showErrorToast } from '@components/Toast';
import { showSessionExpired } from '../components/SessionTimeoutModal';

// only the API EPs below provide error messages
const EPS_WITH_ERROR_HANDLING = [
  '/blueprints/',
  '/benchmarkorganizations/bulk_create/',
  '/users/?blueprint=',
  '/orgusers/',
  '/diligence/',
  '/dashboards/',
  '/table/',
  '/v2/stories/get_cube_token/',
];

 async function genericRequest(
  route,
  payload,
  method,
  isJsonResp,
  throwError,
  env
) {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return;
  }

  const response = await fetch(`${env}${route}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: payload ? JSON.stringify(payload) : null,
  });

  if (!response.ok) {
    if (response.status === 500) {
      const errorMessage =
        "We're sorry, something went wrong on Aura. Please try again in a few minutes.";
      showErrorToast(errorMessage, 'ServerError');
    } else if (response.status === 401) {
      const errorMessage =
        'For security purposes, your session has expired. Please refresh your window to reconnect';
      showSessionExpired(errorMessage, 'SessionExpired');
    } else if (EPS_WITH_ERROR_HANDLING.some(ep => route.includes(ep))) {
      const resErr = await response.json();
      const errorMessage = Object.prototype.hasOwnProperty.call(
        resErr,
        'errorMessage'
      )
        ? resErr.errorMessage
        : // case_code validation from Backend to handle custom response
          resErr.case_code || 'An error occurred, please try again.';
      showErrorToast(errorMessage, errorMessage);
      throw new Error(errorMessage);
    } else if (throwError) {
      throw new Error(throwError);
    }
  }

  if (method === 'DELETE' || !isJsonResp) {
    return await response;
  }

  return await response.json();
}

export default async function request(
  route,
  payload = null,
  method = 'POST',
  isJsonResp = true,
  throwError
) {
  const enviroment = String(env('REACT_APP_API_URL'))
  return genericRequest(
    route,
    payload,
    method,
    isJsonResp,
    throwError,
    enviroment);
}
export async function requestCoreApi(
  route,
  payload = null,
  method = 'POST',
  isJsonResp = true,
  throwError
) {
  const enviroment = String(env('REACT_APP_CORE_API_URL'))
  return genericRequest(
    route,
    payload,
    method,
    isJsonResp,
    throwError,
    enviroment);
}


export async function requestUrl(
  route, url, hasAuth = true
) {
  const enviroment = String(env('REACT_APP_API_URL'))
  return genericRequestUrl(route, url, hasAuth, enviroment);
}
export async function requestUrlCoreApi(
  route, url, hasAuth = true
) {
  const enviroment = String(env('REACT_APP_CORE_API_URL'))
  return genericRequestUrl(route, url, hasAuth, enviroment );
}

export async function genericRequestUrl(route, url, hasAuth, env ) {
  const response = await fetch(
    url || `${env}${route}`,
    {
      headers: hasAuth
        ? {
            Authorization: `Bearer ${getAccessToken()}`,
          }
        : {},
    }
  );
  return response;
}

request.post = async function post(route, payload, isJsonResp) {
  return await request(route, payload, 'POST', isJsonResp);
};
request.put = async function put(route, payload, isJsonResp) {
  return await request(route, payload, 'PUT', isJsonResp);
};

request.get = async function get(route, payload) {
  return await request(route, payload, 'GET');
};

request.patch = async function patch(route, payload, throwError) {
  return await request(route, payload, 'PATCH', true, throwError);
};

request.delete = async function delete_(route, payload) {
  return await request(route, payload, 'DELETE');
};

requestCoreApi.post = async function post(route, payload, isJsonResp) {
  return await requestCoreApi(route, payload, 'POST', isJsonResp);
};
requestCoreApi.put = async function put(route, payload, isJsonResp) {
  return await requestCoreApi(route, payload, 'PUT', isJsonResp);
};

requestCoreApi.get = async function get(route, payload) {
  return await requestCoreApi(route, payload, 'GET');
};

requestCoreApi.patch = async function patch(route, payload, throwError) {
  return await requestCoreApi(route, payload, 'PATCH', true, throwError);
};

requestCoreApi.delete = async function delete_(route, payload) {
  return await requestCoreApi(route, payload, 'DELETE');
};
