import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/ErrorBoundary.module.scss';
import Text from '@components/text';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.container} panel={this.props.panel}>
          <div>
            <Text
              type="H5Semibold"
              textAlign={'center'}
              style={{ marginBottom: '15px' }}
            >
              {this.props.title || 'Whoops!'}
            </Text>
            <Text type="CaptionRegular" textAlign={'center'}>
              {this.props.message || 'Something went wrong, please try again.'}
            </Text>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  panel: PropTypes.bool,
  children: PropTypes.object,
};

export default ErrorBoundary;
