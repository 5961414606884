import PropTypes from 'prop-types';
import Icon from '@components/icon';

import styles from './loader.module.scss';

export function Spinner({
  // backgroundColor = 'transparent',
  size = 'medium',
  height = '24px',
  width = '24px',
  icon = 'coloredTailSpinLoader',
  // isDeterminate,
  // hasDarkBackground,
  // determinatePercentComplete,
}) {
  // TODO: implement size, isDeterminate, hadDarkBackground, determinatePercentComplete
  // Note: Currently only returns small spinner for use on light backgrounds
  return (
    <div className={styles['spinnerDiv'] + ' ' + styles[size]}>
      <Icon
        className={styles.loader}
        icon={icon}
        fill={'none'}
        size={size}
        width={width}
        height={height}
        viewBox="0 0 38 38"
      />
    </div>
  );
}

Spinner.propTypes = {
  height: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
};
