import { Spinner } from './index';
import styles from './loader.module.scss';

export default function FallbackSpinner({
  size = '80px',
  containerStyle = {},
}) {
  return (
    <div className={styles.fallback} style={containerStyle}>
      <Spinner height={size} width={size} />
    </div>
  );
}
