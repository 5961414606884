import { createRoot } from 'react-dom/client';
import App from './views/app/App';
import Providers from './components/Providers';

import 'antd/dist/antd.min.css';
import '@common/mixpanel';
import '@styles/index.scss';
import '@auraintel/client_components/dist/style.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Providers>
    <App />
  </Providers>
);
