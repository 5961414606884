import PropTypes from 'prop-types';
import { Modal } from '../../components/modal';
import Icon from '@components/icon';
import Task from './Task';

import styles from './styles/index.module.scss';

export default function Tasks({
  isModalVisible,
  setIsModalVisible,
  tasks,
  airflowDAGRuns,
}) {
  return (
    <Modal
      className={styles.jobsRunningModal}
      contentClassName={styles.contentClassName}
      headerText={
        <div className={styles.modalTitleDiv}>
          <div className={styles.jobsRunningDiv}>
            <div className={styles.jobsRunning}>
              {tasks?.length || airflowDAGRuns?.length || 0}
            </div>
          </div>
          <div className={styles.modalTitle}>Processing data...</div>
        </div>
      }
      hasCloseButton
      closeIcon={
        <Icon className={styles.closeIcon} icon="cross" viewBox="0 0 24 24" />
      }
      isVisible={isModalVisible}
      onClose={() => {
        setIsModalVisible(false);
      }}
      footerButtonPropsList={[]}
      contentComponent={
        <>
          {tasks?.map((task, index) => (
            <Task
              key={task.id}
              task={task}
              index={index}
              setIsModalVisible={setIsModalVisible}
            />
          ))}
          {airflowDAGRuns?.map((dagRun, index) => (
            <Task
              key={dagRun.dag_run_id}
              dagRun={dagRun}
              index={index}
              setIsModalVisible={setIsModalVisible}
            />
          ))}
        </>
      }
    ></Modal>
  );
}

Tasks.propTypes = {
  isModalVisible: PropTypes.bool,
  jobsRunning: PropTypes.array,
  setIsModalVisible: PropTypes.func,
  tasks: PropTypes.array,
  airflowDAGRuns: PropTypes.array,
};
