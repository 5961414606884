import { Button } from '@components/button';
import Icon from '@components/icon';
import updateIcon from '@components/icon/updateIcon.svg';
import Text from '@components/text';
import { Modal } from 'antd';
import React from 'react';

import './styles/antd-overrides.scss';

const successModal = ({
  heading = 'Updates!',
  message,
  buttonMessage = 'Close',
  icon,
}) => {
  const handleClick = () => {
    Modal.destroyAll();
  };
  Modal.info({
    content: (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            rowGap: '12px',
          }}
        >
          {icon ? (
            <Icon
              icon={icon || 'warning'}
              viewBox="2 5 20 14"
              fill="none"
              size="large"
            />
          ) : (
            <img
              src={updateIcon}
              alt="Aura"
              style={{ width: '35px', height: 'inherit' }}
            />
          )}
          <Text type="SubheadingSemibold" style={{ marginBottom: '8px' }}>
            {heading}
          </Text>
        </div>
        <Text
          type="CaptionRegular"
          style={{
            color: 'var(--gray-600)',
            margin: '6px 0px 18px 0px',
            textAlign: 'center',
          }}
        >
          {message}
        </Text>
        <Button
          type={'primary'}
          title={buttonMessage}
          onClick={handleClick}
          style={{ width: '100%', margin: 'auto 0' }}
        />
      </div>
    ),
    centered: true,
    maskClosable: false,
    okButtonProps: { style: { display: 'none' } },
    cancelButtonProps: { style: { display: 'none' } },
    className: 'errorModal',
    icon: null,
    closable: false,
    style: { borderRadius: '8px' },
    zIndex: 1065,
  });
};

export { successModal };
