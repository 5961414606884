import { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles/Icon.module.scss';

let allIcons;
import('./Icons').then(({ icons }) => {
  allIcons = icons;
});

export default function Icon({
  icon,
  size = 'medium',
  fill = 'currentColor',
  children,
  ...rest
}) {
  const svgPath = children || allIcons[icon];
  const px = {
    xxsmall: '12px',
    xsmall: '16px',
    small: '20px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
  }[size];
  return (
    <svg
      width={px}
      height={px}
      viewBox={rest.viewBox || '0 0 32 32'}
      fill={fill}
      {...rest}
      className={classNames(rest.className, styles.icon, {
        [styles.interactive]: rest.onMouseOver || rest.onClick,
      })}
      title={icon}
    >
      {svgPath}
    </svg>
  );
}

Icon.propTypes = {
  fill: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
};

export function LazyIcon(props) {
  const SVGPath = lazy(() => import(`./SVGPaths/${props.icon}`));
  return (
    <Suspense fallback={<div>...</div>}>
      <Icon {...props}>
        <SVGPath />
      </Icon>
    </Suspense>
  );
}
