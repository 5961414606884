import { BrandLogo } from '@components/brand';
import { LazyIcon } from '@components/icon';
import { useStateContext } from '@state';
import { Link } from 'react-router-dom';
import styles from './styles/AppLoading.module.scss';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';

const MaintenancePage = ({ user }) => {
  const { isCorporateClient } = useStateContext();
  const userHelpdeskMail = getUserHelpdeskMail(checkIsBainUser(user.email));
  return (
    <>
      <div
        style={{
          backgroundColor: '#f7f8fb',
          zIndex: '20',
          border: 'none',
          position: 'sticky',
          top: '0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '48px',
          padding: '0px 32px',
        }}
      >
        <Link to={isCorporateClient ? '/corporate' : '/'}>
          <LazyIcon icon={'auraLogo'} size="large" viewBox="8 4 32 40" />
        </Link>
      </div>
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          left: '50%',
          top: '50%',
          WebkitTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className={styles.logo}>
          <BrandLogo />
        </div>
        <br></br>
        <h1>We are currently under maintenance</h1>
        <p>
          We're working hard to improve our Portal for you and will be back up
          and running shortly.
        </p>
        <p>
          If you need immediate assistance, please reach out to us at{' '}
          <a href={`mailto:` + userHelpdeskMail}>{userHelpdeskMail}</a>.
        </p>
      </div>
    </>
  );
};
export default MaintenancePage;
