import { BGTaskType } from '@common/constants';
import { Event, track } from '@common/mixpanel';
import { Button } from '@components/button';
import Dropdown from '@components/dropdown/Dropdown';
import Icon, { LazyIcon } from '@components/icon';
import Text from '@components/text';
import { useStateContext } from '@state';
import { useAuthContext } from '@state/auth';
import { useBackgroundTasksContext } from '@state/backgroundTasks';
import {
  checkIsBainUser,
  checkIsDragoneerUser,
  checkIsTigerUser,
  getUserHelpdeskMail,
} from '@util/email';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ShareProjectModal from '../project/ShareProjectModal';

import styles from './styles/Header.module.scss';

// images
import { triallUserMsg } from '../../common/constants';
import { checkTrialAccess } from '../../common/helper';
import { getDates } from '../../util/api';
import UpperHeader from './UpperHeader';
import bainLogo from './images/bain_logo.png';
import dragoneerLogo from './images/dragoneer_logo.png';
import tigerLogo from './images/tiger_logo.png';

export const UserIcon = memo(
  ({ userNickname, width = '30px', height = '30px' }) => (
    <div className={styles.userIcon} style={{ width, height }}>
      {userNickname[0].toUpperCase()}
    </div>
  )
);

UserIcon.propTypes = {
  height: PropTypes.string,
  userNickname: PropTypes.string,
  width: PropTypes.string,
};

const noProjectPaths = [
  '/',
  '/admin',
  '/insights-hub',
  '/company-search',
  '/company-search/*',
  '/lead-finder',
  '/job-postings',
  '/talent-pool',
  '/databox',
];

const preventRenderPaths = ['/welcome', '/companies'];

const getDropdownItems = user => {
  const dropdownItems = [
    {
      key: 'headerLogo',
      label: user.email,
      nickName: user.nickname,
    },
    {
      key: 'helpcenter',
      label: 'Help Center',
      icon: 'vector',
    },
    {
      key: 'contactus',
      label: 'Contact Us',
      icon: 'share',
    },
    {
      key: 'signout',
      label: 'Sign Out',
      icon: 'union',
    },
  ];

  // Disable temporarily
  // if (userRole === 'Admin') {
  //   dropdownItems.splice(2, 0, {
  //     key: 'admindashboard',
  //     label: 'Admin Dashboard',
  //     icon: 'dashboard',
  //   });
  // }

  return dropdownItems;
};

const GoHome = memo(() => {
  const { isCorporateClient } = useStateContext();

  return (
    <Link to={isCorporateClient ? '/corporate' : '/'}>
      <LazyIcon icon={'auraLogo'} size="large" viewBox="8 4 32 40" />
    </Link>
  );
});

const Breadcrumbs = memo(({ breadcrumbs }) => (
  <div className={styles.breadcrumbContainer}>
    {breadcrumbs.map((breadcrumb, index) => {
      return (
        <Link to={breadcrumb.route} key={index}>
          <div className={styles.breadcrumb}>
            <Text type="SubheadingMedium">&nbsp;/ {breadcrumb.label}</Text>
          </div>
        </Link>
      );
    })}
  </div>
));

const MainMenu = memo(({ user, userRole }) => {
  const { push } = useHistory();
  const isTigerUser = useMemo(() => checkIsTigerUser(user.email), [user]);
  const isBainUser = useMemo(() => checkIsBainUser(user.email), [user]);
  const isDragoneerUser = useMemo(() => checkIsDragoneerUser(user.email), [
    user,
  ]);
  const userHelpdeskMail = getUserHelpdeskMail(isBainUser);

  const { tasks } = useBackgroundTasksContext();
  const { setShowIncompleteDownloadsAlert } = useStateContext();
  const incompleteDownloadTasks = tasks?.filter(
    task => !task.done && [BGTaskType.EXPORT].includes(task.type)
  );

  const handleDropdownSelect = useCallback(
    selectedOption => {
      switch (selectedOption) {
        case 'signout': {
          if (incompleteDownloadTasks?.length > 0) {
            // prompt user to confirm before leaving the page if there are any incomplete downloads
            setShowIncompleteDownloadsAlert(true);
          } else {
            push('/logout');
            track(Event.LOGOUT);
          }
          break;
        }
        case 'admindashboard': {
          push('/admin');
          break;
        }
        case 'contactus': {
          const emailTab = window.open(`mailto:` + userHelpdeskMail, '_self');
          emailTab.focus();
          break;
        }
        case 'helpcenter': {
          const newTab = window.open('https://auraintel.zendesk.com/hc/en-us', '_blank');
          newTab.focus();
          track(Event.DOCUMENTATION_OPENED);
          break;
        }
        default:
          return;
      }
    },
    [incompleteDownloadTasks?.length, push, setShowIncompleteDownloadsAlert]
  );
  return (
    <Dropdown
      selectedKey={
        <>
          {(isTigerUser || isBainUser || isDragoneerUser) && (
            <img
              src={
                isTigerUser ? tigerLogo : isBainUser ? bainLogo : dragoneerLogo
              }
              alt={
                isTigerUser
                  ? 'Tiger Global logo'
                  : isBainUser
                  ? 'Bain & Company logo'
                  : 'Dragoneer Investment Group Logo'
              }
              className={styles.companyLogo}
            />
          )}
          <div className={styles.userButtonContainer} id="user-icon">
            <UserIcon userNickname={user.nickname} />
          </div>
        </>
      }
      onChange={selectedOption => handleDropdownSelect(selectedOption)}
      hasButtonOutline={isTigerUser || isBainUser || isDragoneerUser}
      items={getDropdownItems(user, userRole)}
      customClass={
        isTigerUser || isBainUser || isDragoneerUser
          ? styles.userDropdown
          : styles.userDropdownNoLogo
      }
      minWidth={isTigerUser || isBainUser || isDragoneerUser ? '100px' : '50px'}
      showCaretIcon={false}
    />
  );
});

export default function Header() {
  const {
    user,
    userRole,
    enableWalkThrough,
    isTrialExpired,
    isTrialUser,
  } = useAuthContext();
  const {
    breadcrumbs,
    showShareProjectModal,
    setShareProjectModalVisible,
    isCorporateClient,
  } = useStateContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const showTrialBanner = isTrialUser && pathname !== '/trial-expired';

  const hasProject = useMemo(() => !noProjectPaths.includes(pathname), [
    pathname,
  ]);
  const preventRender = useMemo(() => preventRenderPaths.includes(pathname), [
    pathname,
  ]);
  const handleShareProject = () => {
    const msg = triallUserMsg.share_project_msg;
    if (checkTrialAccess(isTrialUser, msg)) return;
    setShareProjectModalVisible(true);
  };

  const homePageRoute = useRouteMatch('/');

  const [data, setData] = useState({
    recordsUpdatedUntil: '',
    updateFrequency: '',
    updatedOn: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDates();
        setData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isTrialUser && isTrialExpired && pathname != '/trial-expired') {
      history.push('/trial-expired');
    }
  }, [pathname, isTrialExpired, isTrialUser]);

  useEffect(() => {
    if ((!isTrialUser || !isTrialExpired) && pathname === '/trial-expired') {
      history.push('/');
    }
  }, [isTrialExpired, isTrialUser]);

  return preventRender ? null : (
    <>
      {showTrialBanner && <UpperHeader />}
      <div
        className={classnames(styles.container, {
          [styles.bgGray]: pathname === '/',
        })}
        style={{ top: showTrialBanner ? '26px' : 0 }}
      >
        <div className={styles.leftActions}>
          <GoHome />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div className={styles.rightActions}>
          <div id="datesContainer" className={styles.refreshDates}>
            <Text type="CaptionSemibold">Data refresh dates</Text>
            <Tooltip
              overlayClassName={styles.tooltipContainer}
              overlayStyle={{ position: 'fixed' }}
              placement="bottom"
              color="var(--gray-100)"
              title={() => (
                <div className={styles.tooltipContent}>
                  <div className={styles.tooltipText}>
                    <ul>
                      <li>
                        Profile data: records until{' '}
                        {data?.recordsUpdatedUntil || '-'}, updated on{' '}
                        {data?.updatedOn || '-'}
                      </li>
                      <li>
                        Job postings: updated {data?.updateFrequency || '-'}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            >
              <Icon
                icon="infoIcon"
                className={styles.infoIconButton}
                viewBox="-0.5 1 13 10"
              />
            </Tooltip>
          </div>
          {hasProject &&
            !isCorporateClient &&
            pathname !== '/trial-expired' && (
              <Button
                id="share-project-btn"
                type="secondary"
                compact={true}
                title="Share Project"
                icon="shareProject"
                iconSize="xsmall"
                viewBox="0 0 30 30"
                iconFill="#ce0b80"
                onClick={handleShareProject}
              />
            )}
          {homePageRoute?.isExact ? (
            <Icon
              onClick={enableWalkThrough}
              icon="help"
              type="medium"
              viewBox="0 0 24 24"
              style={{ marginRight: '0.5rem' }}
            />
          ) : null}
          <MainMenu user={user} userRole={userRole} />
        </div>
        {(hasProject || pathname === '/insights-hub') &&
          showShareProjectModal && (
            <ShareProjectModal
              setIsModalVisible={setShareProjectModalVisible}
            />
          )}
      </div>
    </>
  );
}
