import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';
import Icon from '../icon';

export function BasicCheckbox({
  value,
  defaultValue = false,
  onChange = () => {},
  preventDefault = false,
  disabled = false,
}) {
  const [isChecked, setIsChecked] = useState(value || defaultValue);
  // events
  useEffect(() => {
    if (value !== undefined) {
      setIsChecked(value);
    }
  }, [value]);

  const handleInputChange = () => {
    onChange(!isChecked);

    if (!preventDefault) {
      setIsChecked(!isChecked);
    }
  };

  // render
  return (
    <label className={styles.basicContainer}>
      {isChecked ? (
        <div className={styles.checkedBox}>
          {disabled ? 
          <Icon icon="dashwhite" size="xlarge" fill="#fff" stroke="#fff" strokeWidth="2px" viewBox="1.2 14.7 14 14"/>
          :
          <Icon icon="checkmark" size="xsmall" viewBox="-2 -3.5 14 14" />
          }
        </div>
      ) : (
        <div className={styles.uncheckedBox} />
      )}
      <input
        type="checkbox"
        value={isChecked}
        onChange={handleInputChange}
        className={styles.hiddenCheckBox}
        disabled={disabled}
      />
    </label>
  );
}

BasicCheckbox.propTypes = {
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  preventDefault: PropTypes.bool,
  value: PropTypes.bool,
};
