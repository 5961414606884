import { message } from 'antd';
import Text from '@components/text';
import Icon from '@components/icon';

import './styles/antd-overrides.scss';

let prevErrorMessage = null;

const showErrorToast = (errorMessage, key, duration = 4) => {
  if (prevErrorMessage === errorMessage && key) {
    message.destroy(key);
  }

  prevErrorMessage = errorMessage;

  message.error({
    content: (
      <div>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'start' }}
        >
          <Icon icon="warning" viewBox="0 1 30 45" fill="none" size="xlarge" />
          <Text type="SubheadingSemibold" style={{ marginBottom: '8px' }}>
            Sorry, an error occurred!
          </Text>
        </div>
        <Text type="CaptionRegular" style={{ color: 'var(--gray-600)' }}>
          {errorMessage}
        </Text>
      </div>
    ),
    duration,
    className: 'errorToast',
    icon: <></>, // null is not accepted
    key,
  });
};

export { showErrorToast };
