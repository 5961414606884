import PropTypes from 'prop-types';
import Icon from '@components/icon';
import ProgressBar from '@components/ProgressBar';
import styles from './styles/Tasks.module.scss';
import { DAGRun } from '@common/constants';

export default function Task({ index, task, dagRun }) {
  // get the latest from the retrieved jobs
  const job = task?.jobs?.[0];
  return (
    <div
      className={styles.jobTile}
      key={`${task?.id || dagRun?.dag_run_id}_'${index}`}
    >
      <div className={styles.tileTitle}>
        {job?.type || task?.type || dagRun?.name}
      </div>
      <div className={styles.tileDescription}>
        {job?.description || task?.description || dagRun?.description}
        {job && 'parent_company' in job.metadata
          ? ': ' + job.metadata.parent_company.join(', ')
          : ''}
      </div>
      <ProgressBar
        error={job?.status === 'FAILED' || task?.error ? true : false}
        percent={job?.percentage_complete ?? task?.percentage_complete}
        isInfinite={!!dagRun}
      />
      {(job?.status === 'FAILED' ||
        task?.error ||
        dagRun?.state === DAGRun.FAILURE) && (
        <div className={styles.failure}>
          <div className={styles.failureInfo}>
            <div className={styles.warningIcon}>
              <Icon icon="warning" viewBox="0 -2 32 32" fill="none" />
            </div>
            <div className={styles.failureMsg}>Something went wrong</div>
          </div>
        </div>
      )}
      {(job?.status === 'SUCCESS' ||
        task?.done ||
        dagRun?.state === DAGRun.SUCCESS) &&
        (job?.percentage_complete === 100 ||
          task?.percentage_complete === 100) && (
          <div className={styles.success}>
            <div className={styles.successInfo}>
              <div className={styles.successIcon}>
                <Icon icon="statusOk" viewBox="0 -3 32 32" fill="none" />
              </div>
              <div className={styles.successMsg}>Completed</div>
            </div>
          </div>
        )}
    </div>
  );
}

Task.propTypes = {
  job: PropTypes.shape({
    description: PropTypes.any,
    metadata: PropTypes.shape({
      dag_id: PropTypes.string,
      parent_company: PropTypes.array,
    }),
    percentage_complete: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
  }),
  jobIndex: PropTypes.number,
  task: PropTypes.object,
  taskIndex: PropTypes.number,
  dagRun: PropTypes.object,
};
