import { useState } from 'react';
import Joyride from 'react-joyride';
import { useRouteMatch } from 'react-router';
import { useAuthContext } from '@state/auth';
import { diligenceSteps, GetSteps } from './steps';
import Tooltip from './Tooltip';
import { useEffect } from 'react';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';

function WalkThrough() {
  const [stepIndex, setStepIndex] = useState();
  const [runWalkThrough, setRunWalkThrough] = useState(false);
  const {
    firstTimeUser,
    saveFirstTimeUser,
    canShowWalkThrough,
    user,
  } = useAuthContext();

  const diligenceRoute = useRouteMatch('/insights-hub');
  const userHelpdeskMail = getUserHelpdeskMail(checkIsBainUser(user.email));

  useEffect(() => {
    if (!diligenceRoute?.isExact && firstTimeUser && !firstTimeUser.homepage) {
      setTimeout(() => setRunWalkThrough(true), 100);
    }
  }, [diligenceRoute?.isExact, firstTimeUser, setRunWalkThrough]);

  useEffect(() => {
    if (
      diligenceRoute?.isExact &&
      firstTimeUser &&
      !firstTimeUser.diligence &&
      canShowWalkThrough
    ) {
      setTimeout(() => setRunWalkThrough(true), 100);
    }
  }, [
    canShowWalkThrough,
    diligenceRoute?.isExact,
    firstTimeUser,
    setRunWalkThrough,
  ]);

  return (
    <Joyride
      run={runWalkThrough}
      steps={
        diligenceRoute?.isExact ? diligenceSteps : GetSteps(userHelpdeskMail)
      }
      continuous
      hideBackButton
      scrollToFirstStep
      styles={{
        spotlight: { borderRadius: 8 },
      }}
      floaterProps={{
        hideArrow: !diligenceRoute?.isExact && stepIndex === 0,
        disableAnimation: true,
      }}
      callback={({ index, type }) => {
        if (stepIndex !== index) {
          setStepIndex(index);
        }
        // mark interactive walk through at multiple routes as done
        if (type === 'tour:end') {
          if (diligenceRoute?.isExact) {
            saveFirstTimeUser({
              ...firstTimeUser,
              diligence: true,
            });
          } else {
            saveFirstTimeUser({
              ...firstTimeUser,
              homepage: true,
            });
          }
          setRunWalkThrough(false);
        }
      }}
      tooltipComponent={Tooltip}
    />
  );
}

export default WalkThrough;
