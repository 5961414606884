import { isDev } from '@common/platform';
import { getDomain } from '@util/email';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { env } from './env';

const config = isDev()
  ? {
      debug: false,
      ignore_dnt: true,
    }
  : {};

const MIXPANEL_VERSIONS = {
  v2: env('MIXPANEL_TOKEN_V2'),
};

Object.entries(MIXPANEL_VERSIONS).forEach(([version, token]) => {
  mixpanel.init(token, config, version);
});

const applyCallbackToMixpanelInstanes = callback => {
  Object.keys(MIXPANEL_VERSIONS).forEach(version => {
    callback(mixpanel[version]);
  });
};

export const setProfile = ({ id, email }) => {
  applyCallbackToMixpanelInstanes(mixpanelInstance => {
    mixpanelInstance.people.set({
      'Email Domain': getDomain(email),
      $email: email,
    });
    mixpanelInstance.identify(id);
  });
};

export const registerUserInfo = (
  { subscription, email_address },
  isTrialUser
) => {
  applyCallbackToMixpanelInstanes(mixpanelInstance => {
    mixpanelInstance.register({
      license: subscription,
      'Email Domain': getDomain(email_address),
      $email: email_address,
      'User Type': isTrialUser ? 'Trial' : 'Premium',
    });
  });
};

export const track = (name, props) => {
  applyCallbackToMixpanelInstanes(mixpanelInstance => {
    mixpanelInstance.track(name, props);
  });
};

export const useTrackPage = (name, props, isLoaded = true) => {
  useEffect(() => {
    if (isLoaded) {
      track(name, props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
};

export const Event = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SIGNUP: 'SIGNUP',
  DOCUMENTATION_OPENED: 'DOCUMENTATION_OPENED',
  NEW_PROJECT_CREATED: 'DILIGENCE_NEW_PROJECT_CREATED',
  INSIGHTS_NEW_PROJECT_CREATED_EVENT: 'INSIGHTS_NEW_PROJECT_CREATED',
  INSIGHTS_NEW_PROJECT_CREATED_BAIN_EVENT: 'INSIGHTS_NEW_PROJECT_CREATED_BAIN',
  INSIGHTS_NEW_PROJECT_CONFIRM_EVENT: 'INSIGHTS_NEW_PROJECT_CONFIRM',
  PROJECT_HOME_PAGE_LOADED: 'DILIGENCE_PROJECT_HOME_PAGE_LOADED',
  PROJECT_DATA_DOWNLOADED: 'DILIGENCE_PROJECT_DATA_DOWNLOADED',
  INSIGHTS_DASHBOARD_DATA_DOWNLOADED_EVENT:
    'INSIGHTS_DASHBOARD_DATA_DOWNLOADED',
  INSIGHTS_PROJECT_SHARED_EVENT: 'INSIGHTS_PROJECT_SHARED',
  PROJECT_CUSTOM_ROLES_ADDED: 'DILIGENCE_PROJECT_CUSTOM_ROLES_ADDED',
  PROJECT_DASHBOARDS_PAGE_LOADED: 'DILIGENCE_PROJECT_DASHBOARDS_PAGE_LOADED',
  PROJECT_AT_A_GLANCE_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_AT_A_GLANCE_DASHBOARD_LOADED',
  PROJECT_SOURCES_DESTINATIONS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_SOURCES_DESTINATIONS_DASHBOARD_LOADED',
  PROJECT_SKILLS_CAPABILITIES_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_SKILLS_CAPABILITIES_DASHBOARD_LOADED',
  PROJECT_ATTRITION_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_ATTRITION_DASHBOARD_LOADED',
  PROJECT_GENDER_DASHBOARD_LOADED: 'DILIGENCE_PROJECT_GENDER_DASHBOARD_LOADED',
  PROJECT_ETHNICITY_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_ETHNICITY_DASHBOARD_LOADED',
  PROJECT_TOP_SCHOOLS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_TOP_SCHOOLS_DASHBOARD_LOADED',
  PROJECT_MAJORS_MINORS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_MAJORS_MINORS_DASHBOARD_LOADED',
  PROJECT_TOP_CERTIFICATIONS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_TOP_CERTIFICATIONS_DASHBOARD_LOADED',
  PROJECT_GEOGRAPHIC_US_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_GEOGRAPHIC_US_DASHBOARD_LOADED',
  INSIGHTS_PROJECT_DASHBOARDS_GEOGRAPHIC_SCREEN:
    'INSIGHTS_PROJECT_DASHBOARDS_GEOGRAPHIC_SCREEN',
  PROJECT_GEOGRAPHIC_METRO_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_GEOGRAPHIC_METRO_DASHBOARD_LOADED',
  PROJECT_ROLE_ALLOCATION_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_ROLE_ALLOCATION_DASHBOARD_LOADED',
  PROJECT_ROLE_ALLOCATION_TS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_ROLE_ALLOCATION_TS_DASHBOARD_LOADED',
  PROJECT_FOCUS_FIRMS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_FOCUS_FIRMS_DASHBOARD_LOADED',
  PROJECT_TENURE_DASHBOARD_LOADED: 'DILIGENCE_PROJECT_TENURE_DASHBOARD_LOADED',
  PROJECT_PROMOTION_TRENDS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_PROMOTION_TRENDS_DASHBOARD_LOADED',
  PROJECT_HIRES_EXITS_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_HIRES_EXITS_DASHBOARD_LOADED',
  PROJECT_RETURNING_ALUMNI_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_RETURNING_ALUMNI_DASHBOARD_LOADED',
  PROJECT_SENIORITY_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_SENIORITY_DASHBOARD_LOADED',
  PROJECT_SALES_LOADED: 'DILIGENCE_PROJECT_SALES_LOADED',
  COMPANY_SEARCH_INITIATED: 'SOURCING_COMPANY_SEARCH_INITIATED',
  COMPANY_SEARCH_RESULTS_SHOWN: 'SOURCING_COMPANY_SEARCH_RESULTS_SHOWN',
  COMPANY_SEARCH_DATA_EXPORTED: 'SOURCING_COMPANY_SEARCH_DATA_EXPORTED',
  COMPANY_SEARCH_FILTER_APPLIED: 'SOURCING_COMPANY_SEARCH_FILTER_APPLIED',
  TALENT_PREVIEW_GENERATED: 'LEAD_FINDER_TALENT_PREVIEW_GENERATED',
  CUSTOMER_PREVIEW_GENERATED: 'LEAD_FINDER_CUSTOMER_PREVIEW_GENERATED',
  DATABOX_PROFILES_DOWNLOADED: 'DATABOX_PROFILES_DOWNLOADED',
  JOB_POSTINGS_OPENED: 'JOB_POSTINGS_OPENED',
  CORPORATE_VIEW_OPENED: 'CORPORATE_VIEW_OPENED',
  TALENT_POOL_OPENED: 'TALENT_POOL_OPENED',
  TALENT_POOL_CHARTS_LOADED: 'TALENT_POOL_CHARTS_LOADED',
  TALENT_POOL_SUMMARY_CITY_VIEW_LOADED: 'TALENT_POOL_SUMMARY_CITY_VIEW_LOADED',
  TALENT_POOL_DEI_GENDER_CITY_VIEW_LOADED:
    'TALENT_POOL_DEI_GENDER_CITY_VIEW_LOADED',
  TALENT_POOL_DEI_ETHNICITY_CITY_VIEW_LOADED:
    'TALENT_POOL_DEI_ETHNICITY_CITY_VIEW_LOADED',
  PROJECT_DEEPDIVE_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_DEEPDIVE_DASHBOARD_LOADED',
  PROJECT_INSIGHT_OVERVIEW_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_INSIGHT_OVERVIEW_DASHBOARD_LOADED',
  PROJECT_EDUCATION_DASHBOARD_LOADED:
    'DILIGENCE_PROJECT_EDUCATION_DASHBOARD_LOADED',
  INSIGHTS_PROJECT_DASHBOARDS_GEOGRAPHIC_GEOGRAPHICAL_FOOTPRINT_GLOBAL_MAP_MOUSE_HOVER_EVENT:
    'INSIGHTS_PROJECT_DASHBOARDS_GEOGRAPHIC_GEOGRAPHICAL_FOOTPRINT_GLOBAL_MAP_MOUSE_HOVER',
  INSIGHTS_PROJECT_DASHBOARD_SCREEN: 'INSIGHTS_PROJECT_DASHBOARD_SCREEN',
  HOME_PAGE_SCREEN: 'HOME_PAGE_SCREEN',
  INSIGHTS_COMPANIES_FILTER_APPLIED_EVENT: 'INSIGHTS_COMPANIES_FILTER_APPLIED_EVENT',
  INSIGHTS_PROJECT_DASHBOARDS_ATTRITION_ADJUST_YEAR_START_EVENT:
  'INSIGHTS_PROJECT_DASHBOARDS_ATTRITION_ADJUST_YEAR_START_EVENT',
  INSIGHTS_PROJECT_DASHBOARDS_ATTRITION_ADJUST_YEAR_END_EVENT:
  'INSIGHTS_PROJECT_DASHBOARDS_ATTRITION_ADJUST_YEAR_END_EVENT'
};

// transform lead finder's query object into object expected at mixpanel
export const LeadFinderField = {
  'experience.title.name': 'Current Job',
  'experience.start_date': 'Start Date',
  'experience.company.location.country': 'Country',
  'experience.company.name': {
    3: 'Current Company',
    6: 'Past companies',
  },
  'education.school.name': 'School',
  'experience.company.industry': 'Industry',
};

export const DataboxField = {
  job_title: 'Current Job',
  'experience.start_date': 'Start Date',
  location_country: 'Country',
  job_company_name: 'Current Company',
  'experience.company.name': 'Past Companies',
  'education.school.name': 'School',
  'experience.company.industry': 'Industry',
};

// map event by lead finder case type
export const LeadFinderEvent = {
  talent: Event.TALENT_PREVIEW_GENERATED,
  sales: Event.CUSTOMER_PREVIEW_GENERATED,
};
