import { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import Text from '../text';
import Icon from '../icon';
import { UserIcon } from '../../views/app/Header';
import PremiumLabel from '../PremiumLabel';
import { useStateContext } from '@state';
import classnames from 'classnames';

import styles from './Dropdown.module.scss';

const testItems = [
  {
    key: 'optiona',
    label: 'Option A',
    subtitle: 'This is the first option',
  },
  {
    key: 'optionb',
    label: 'Option B',
  },
];

function Dropdown({
  selectedKey = testItems[0].key,
  items = testItems,
  onChange = () => {},
  compact = false,
  minWidth = '150px',
  variableWidth = true,
  hasButtonOutline = true,
  hasCategories = false,
  customClass,
  showCaretIcon = true,
  premiumItems = [],
  canShowPremiumLabel = false,
  containerStyle = {},
  isWithinModal = false,
  disabled = false,
}) {
  const { setShowPremiumModal } = useStateContext();
  const itemToString = item => (item !== undefined ? item.label : '');
  const buttonRef = useRef(null);

  function handleChange(downshiftEvent) {
    if (
      canShowPremiumLabel &&
      premiumItems.includes(downshiftEvent.selectedItem.key)
    ) {
      setShowPremiumModal(true);
    } else {
      onChange(downshiftEvent.selectedItem.key);
    }
  }

  function interceptMenuRender(menuRef) {
    if (!menuRef || !buttonRef.current) {
      return;
    }

    // prevent rerendering if custom position was already calculated
    if (menuRef.style.top || menuRef.style.bottom) {
      return;
    }

    const menuDomRect = menuRef.getBoundingClientRect();
    const buttonDomRect = buttonRef.current.getBoundingClientRect();

    // check if the popup is smaller than the button
    if (menuDomRect.width < buttonDomRect.width) {
      menuRef.style.width = buttonDomRect.width + 'px';
    } else {
      menuRef.style = {};
    }

    if (menuDomRect.bottom > window.innerHeight) {
      const neededHeight = menuDomRect.height;
      if (
        neededHeight > buttonDomRect.bottom - window.innerHeight &&
        neededHeight < buttonDomRect.top - 100
      ) {
        const bottom = isWithinModal
          ? window.innerHeight - buttonDomRect.top
          : buttonDomRect.height;
        menuRef.style.bottom = `${bottom - 10}px`;
      }
    } else {
      const top = isWithinModal
        ? buttonDomRect.y + buttonDomRect.height
        : buttonDomRect.height;
      menuRef.style.top = `${top}px`;
    }
  }

  function getButtonLabel() {
    const selectedItem = items.find(item => item.key === selectedKey);

    if (selectedItem === undefined) {
      return selectedKey;
    } else {
      return selectedItem.label || 'Unknown Key: ' + selectedKey;
    }
  }

  function getCategorizedItems() {
    var output = [];
    var flatOutput = [];
    const categories = new Set();

    // first iterate the array to get all of the categories
    for (const item of items) {
      categories.add(item.category);
    }

    const orderedCategories = Array.from(categories).sort(); //note: undefined will come last if it exists

    // then add each category to the output
    for (const category of orderedCategories) {
      output.push({
        category: category,
        items: [],
      });
    }

    // then iterate the array again to put the data in the category sorted order
    for (const item of items) {
      const outputIndex = orderedCategories.indexOf(item.category);

      const oldItems = output[outputIndex].items;
      const newItems = [...oldItems, item];
      output[outputIndex].items = newItems;
    }

    // finally, iterate the output a final time to assign index values for each item
    var itemIndex = 0;
    for (const categoryIndex in output) {
      var newItems = [];
      for (const item of output[categoryIndex].items) {
        const newItem = { ...item, ...{ index: itemIndex } };
        newItems.push(newItem);
        flatOutput.push(newItem);
        itemIndex++;
      }
      output[categoryIndex].items = newItems;
    }

    return [output, flatOutput];
  }

  var categorizedItems = [];
  var flatCategorizedItems = [];
  var _items = []; // keep an internal definition of items, that changes depending if categories are used or not

  if (hasCategories) {
    [categorizedItems, flatCategorizedItems] = getCategorizedItems();
    _items = flatCategorizedItems;
  } else {
    _items = items;
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: _items,
    itemToString,
    onSelectedItemChange: handleChange,
  });
  const buttonLabel = getButtonLabel();

  const canOpen = isOpen && !disabled;

  return (
    <div
      className={classnames(styles.container, customClass)}
      style={
        variableWidth
          ? { minWidth: minWidth, ...containerStyle }
          : { ...containerStyle }
      }
    >
      <button
        className={classnames(styles.button, {
          [styles.buttonNoOutline]: !hasButtonOutline,
          [styles.compactButton]: compact,
          [styles.buttonVariableWidth]: variableWidth,
          [styles.buttonDisabled]: disabled,
        })}
        type="button"
        {...getToggleButtonProps({ ref: buttonRef })}
      >
        <div className={styles.buttonText}>
          <Text
            type="CaptionMedium"
            style={
              buttonLabel === 'No filter set selected'
                ? { color: '#7d8f9b' }
                : {}
            }
          >
            {buttonLabel}
          </Text>
        </div>
        {showCaretIcon && (
          <Icon
            width={12}
            height={6}
            icon={'dropdown'}
            viewBox="0 0 12 6"
            style={{ opacity: disabled ? 0.5 : 1 }}
          />
        )}
      </button>
      <ul
        {...getMenuProps({
          ref: canOpen ? interceptMenuRender : null,
        })}
        className={classnames({
          [styles.dropdownPanel]: canOpen,
          [styles.closedDropdownPanel]: canOpen === false,
          [styles.fixedPosition]: isWithinModal,
          [styles.absolutePosition]: !isWithinModal,
        })}
      >
        {canOpen &&
          hasCategories &&
          categorizedItems.map(categoryObject => (
            <DropdownCategory
              key={categoryObject.category}
              selectedKey={selectedKey}
              categoryName={categoryObject.category}
              items={categoryObject.items}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
            />
          ))}
        {canOpen &&
          !hasCategories &&
          items.map((item, index) => (
            <DropdownOption
              key={item.key}
              value={item.label}
              highlightedIndex={highlightedIndex}
              item={item}
              index={index}
              subtitle={item.subtitle}
              style={item.customStyle}
              isSelected={item.key === selectedKey}
              getItemProps={getItemProps}
              isPremium={canShowPremiumLabel && premiumItems.includes(item.key)}
            />
          ))}
      </ul>
    </div>
  );
}

Dropdown.propTypes = {
  compact: PropTypes.bool,
  customClass: PropTypes.string,
  hasButtonOutline: PropTypes.bool,
  hasCategories: PropTypes.bool,
  items: PropTypes.array,
  minWidth: PropTypes.string,
  onChange: PropTypes.func,
  selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCaretIcon: PropTypes.bool,
  variableWidth: PropTypes.bool,
  premiumItems: PropTypes.array,
  canShowPremiumLabel: PropTypes.bool,
};

export default memo(Dropdown);

function DropdownCategory({
  categoryName,
  items,
  selectedKey,
  highlightedIndex,
  getItemProps,
}) {
  return (
    <div>
      <Text type="LegendRegular" style={{ color: 'var(--gray-500)' }}>
        {categoryName}
      </Text>
      {items.map(item => (
        <DropdownOption
          key={item.key}
          value={item.label}
          highlightedIndex={highlightedIndex}
          item={item}
          index={item.index}
          subtitle={item.subtitle}
          style={item.customStyle}
          isSelected={item.key === selectedKey}
          getItemProps={getItemProps}
        />
      ))}
    </div>
  );
}

DropdownCategory.propTypes = {
  categoryName: PropTypes.string,
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  items: PropTypes.array,
  selectedKey: PropTypes.string,
};

function DropdownOption({
  value = 'Option',
  item,
  index,
  highlightedIndex,
  getItemProps,
  subtitle,
  isSelected,
  style,
  isPremium,
}) {
  const isHighlighted = highlightedIndex === index;

  return (
    // handle dropdown with user icon in the app header
    item.nickName ? (
      <li className={styles.dropdownOptionUserIcon}>
        <UserIcon userNickname={item.nickName} width={'50px'} height={'50px'} />
        <Text
          type="CaptionMedium"
          style={{ marginTop: '10px', color: '#ce0b80' }}
        >
          {item.label}
        </Text>
      </li>
    ) : (
      <li
        className={classnames(styles.dropdownOption, {
          [styles.selectedOption]: isSelected,
          [styles.highlightedDropdownOption]: isHighlighted,
          [styles.dropdownHeaderOption]: item.icon,
        })}
        style={style}
        {...getItemProps({ item, index })}
      >
        {item.icon && (
          <div style={{ float: 'left', marginRight: '8px' }}>
            <Icon width={13} height={13} icon={item.icon} viewBox="0 0 16 12" />
          </div>
        )}
        <Text
          type="CaptionMedium"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          {value}
          {isPremium ? <PremiumLabel style={{ padding: '3px' }} /> : null}
        </Text>
        {subtitle !== undefined
          ? subtitle.split('\n').map(subtitlePart => (
              <p key={subtitlePart} className={styles.subtitle}>
                <Text type="LegendRegular">{subtitlePart}</Text>
              </p>
            ))
          : null}
      </li>
    )
  );
}

DropdownOption.propTypes = {
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  item: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    nickName: PropTypes.string,
  }),
  style: PropTypes.object,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  isPremium: PropTypes.bool,
};
