import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { useAuthContext } from '@state/auth';
import Text from '@components/text';
import Icon, { LazyIcon } from '@components/icon';
import colors from '../../styles/colors';
import { LazyLoadIcons, isModuleEnabled } from '@common/constants';
import InfoPopOver from '@components/popOvers/InfoPopOver';
import { CaseCodeInputField } from '../projects/ProjectsModal';
import { isValidCaseCode } from '@util/regex';
import { Modal } from '@components/modal';
import { track, Event } from '@common/mixpanel';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';
import JobPostingsUpdate from '@components/JobPostingsUpdate';
import { useFlags } from 'launchdarkly-react-client-sdk';

import styles from './ModuleSelector.module.scss';
import './andt-overrides.scss';

export const checkIsLocked = (title, subscriptions) => {
  let isLocked = false;
  subscriptions.forEach(sub => {
    const nameKey = Object.keys(sub)[0].startsWith(
      title.toLowerCase().slice(0, 3)
    );

    if (nameKey && !sub[Object.keys(sub)[0]]) {
      isLocked = true;
    }
  });

  return isLocked;
};

const RenderModuleWithLock = ({ isLocked, title, children }) => {
  return isLocked ? (
    <Popconfirm
      placement="bottom"
      overlayClassName="popOver"
      title={<PopConfirmText productName={title} />}
      okText="Find out more"
      showCancel={false}
      icon={null}
      trigger={['click']}
      onConfirm={() => {
        const newTab = window.open('https://getaura.ai/', '_blank');
        newTab.focus();
      }}
    >
      {children}
    </Popconfirm>
  ) : (
    children
  );
};

RenderModuleWithLock.propTypes = {
  isLocked: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.object,
};

const PopConfirmText = ({ productName }) => {
  const title =
    productName === 'Insights Hub'
      ? 'Looking to compare companies'
      : productName === 'Sourcing'
      ? 'Looking to find new companies?'
      : '';

  const description =
    productName === 'Insights Hub'
      ? 'Aura’s Insights Hub platform leverages alt-data to enable you to compare against your competitors, or diligence investment targets'
      : productName === 'Sourcing'
      ? 'Aura’s Sourcing platform is built to find new and innovative ways of identifying companies of interest to your business'
      : 'The team here at Aura is currently working hard to revamp our Lead Finder product. We will be reaching out once we have news here';

  return (
    <div>
      <Text
        type="H5Semibold"
        textAlign={'center'}
        style={{ color: 'var(--violet)', marginBottom: '10px' }}
      >
        {productName === 'Lead Finder' ? 'Coming soon!' : 'Access denied!'}
      </Text>
      <Text
        type="CaptionSemibold"
        style={{
          color: 'var(--gray-700)',
          marginBottom: '10px',
          textAlign: 'center',
        }}
      >
        {title}
      </Text>
      <Text
        type="LegendRegular"
        style={{
          color: 'var(--gray-700)',
          marginBottom: '10px',
          textAlign: 'center',
        }}
      >
        {description}
      </Text>
      {title && (
        <>
          <hr
            style={{
              color: 'var(--gray-700)',
            }}
          />
          <Text
            type="LegendRegular"
            style={{
              color: 'var(--gray-700)',
              margin: '10px 0 5px',
              textAlign: 'center',
            }}
          >
            Find out more about what the {productName} platform can do for you
            at getaura.ai
          </Text>
        </>
      )}
    </div>
  );
};

PopConfirmText.propTypes = {
  productName: PropTypes.string,
};

export const SelectionCard = ({
  icon,
  iconViewbox,
  title,
  subtitle,
  width = '216px',
  locked = true,
  showPopoverIcon = true,
}) => {
  return (
    <RenderModuleWithLock isLocked={locked} title={title}>
      <div className={styles.link} style={{ width }}>
        {showPopoverIcon && (
          <div className={styles.infoIcon}>
            <Popconfirm
              placement="bottom"
              overlayClassName="popOver"
              title={<InfoPopOver productName={title} />}
              showCancel={false}
              icon={null}
              trigger={['hover']}
            >
              <Icon icon="info" size="small" viewBox="0 0 24 24" />
            </Popconfirm>
          </div>
        )}
        <div
          className={styles.icon}
          style={{ marginTop: showPopoverIcon ? '25px' : '50px' }}
        >
          {LazyLoadIcons.includes(icon) ? (
            <LazyIcon
              size="large"
              icon={icon}
              viewBox={iconViewbox}
              fill={colors.violet}
            />
          ) : (
            <Icon
              size="large"
              icon={icon}
              viewBox={iconViewbox}
              fill={colors.violet}
            />
          )}
        </div>
        <div className={styles.title}>
          <Text type="H5Semibold" textAlign={'center'}>
            {title}
          </Text>
        </div>
        <div className={styles.subtitle}>
          <Text type="CaptionRegular" textAlign={'center'}>
            {subtitle}
          </Text>
        </div>
        {locked && (
          <Popconfirm
            placement="bottom"
            overlayClassName="popOver"
            title={<PopConfirmText productName={title} />}
            okText="Find out more"
            showCancel={false}
            icon={null}
            trigger={['hover']}
            onConfirm={() => {
              const newTab = window.open('https://getaura.ai/', '_blank');
              newTab.focus();
            }}
          >
            <div className={styles.lockIcon}>
              <Icon size="large" icon="lock" viewBox="0 0 32 32" fill="none" />
            </div>
          </Popconfirm>
        )}
      </div>
    </RenderModuleWithLock>
  );
};

SelectionCard.propTypes = {
  icon: PropTypes.string,
  iconViewbox: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  width: PropTypes.string,
  locked: PropTypes.bool,
  showPopoverIcon: PropTypes.bool,
};

const ModuleCard = memo(({ config, isLocked }) => (
  <SelectionCard
    icon={config.icon}
    iconViewbox={config.iconViewbox}
    title={config.title}
    subtitle={config.subtitle}
    locked={isLocked}
  />
));

export default function ModuleSelector() {
  const { userSubscriptions, user, loginCounts } = useAuthContext();
  const [showCaseCodeModal, setShowCaseCodeModal] = useState(false);
  const [caseCode, setCaseCode] = useState('');
  const [isCaseCodeInvalid, setIsCaseCodeInvalid] = useState(false);
  const history = useHistory();
  const isBainUser = checkIsBainUser(user.email);
  const userHelpdeskMail = getUserHelpdeskMail(isBainUser);

  const { platformFeatureStandaloneJobPostingsModule } = useFlags();

  const MODULES = [
    {
      title: 'Insights Hub',
      icon: 'charts',
      iconViewbox: '0 0 22 28',
      subtitle: 'Research and compare specific companies',
      path: '/insights-hub',
    },
    {
      title: 'Sourcing',
      icon: 'companies',
      iconViewbox: '0 0 32 32',
      subtitle: 'Identify new companies',
      path: '/company-search',
    },
    {
      title: 'Databox',
      icon: 'databox',
      iconViewbox: '0 0 31 30',
      subtitle: 'Search and download talent data',
      path: '/databox',
    },
    {
      title: 'Salary Benchmark',
      icon: 'salary',
      iconViewbox: '0 0 47 41',
      subtitle: 'Close leads with attractive offers',
      path: '/salary-benchmark',
    },
    {
      title: 'Talent Pool',
      icon: 'talentPool',
      iconViewbox: '0 0 36 32',
      subtitle: 'Analyze macro level talent insights',
      path: '/talent-pool',
    },
    ...(platformFeatureStandaloneJobPostingsModule
      ? [
          {
            title: 'Job Postings',
            icon: 'job',
            iconViewbox: '0 0 43 41',
            subtitle: 'Explore hiring trends globally',
            path: '/job-postings',
          },
        ]
      : []),
  ];

  useEffect(() => {
    setIsCaseCodeInvalid(false);
  }, [caseCode]);

  const handleCaseCode = () => {
    if (!isValidCaseCode(caseCode)) {
      setIsCaseCodeInvalid(true);
      return;
    }
    setShowCaseCodeModal(false);

    track(Event.JOB_POSTINGS_OPENED, {
      EmailID: user.email,
      'Case Code': caseCode,
      Module: 'Job Postings',
    });

    history.push('/job-postings');
  };

  const handleCancel = () => {
    setCaseCode('');
    setShowCaseCodeModal(false);
  };

  return (
    <div>
      <div className={styles.headerText}>
        <span role="img" aria-label="welcome emoji">
          👋
        </span>
        {` Welcome ${loginCounts === 1 ? '' : 'back.'}`}
      </div>
      <div className={styles.links} id="module-cards">
        {MODULES.filter(module => isModuleEnabled(module, user)).map(
          moduleSettings => {
            const isLocked = checkIsLocked(
              moduleSettings.title,
              userSubscriptions
            );
            return moduleSettings.title === 'Job Postings' ? (
              <span
                onClick={event => {
                  event.preventDefault();
                  if (isBainUser) {
                    setShowCaseCodeModal(true);
                  } else {
                    history.push('/job-postings');
                  }
                }}
                key={moduleSettings.path}
                style={{ cursor: 'pointer' }}
                id="jp-module-card"
              >
                <ModuleCard config={moduleSettings} isLocked={isLocked} />
              </span>
            ) : (
              <Link
                key={moduleSettings.path}
                to={isLocked ? '/' : moduleSettings.path}
                id={
                  moduleSettings.title
                    .split(' ')
                    .join('-')
                    .toLowerCase() + '-card'
                }
              >
                <ModuleCard config={moduleSettings} isLocked={isLocked} />
              </Link>
            );
          }
        )}
      </div>
      <Modal
        isVisible={showCaseCodeModal}
        headerText="Job Postings"
        contentClassName={styles.caseCodeModalContent}
        contentComponent={
          <CaseCodeInputField
            isCaseCodeInvalid={isCaseCodeInvalid}
            projectCaseCode={caseCode}
            setProjectCaseCode={setCaseCode}
            handleOk={handleCaseCode}
            view="jobPostings"
            userHelpdeskMail={userHelpdeskMail}
          />
        }
        footerButtonPropsList={[
          {
            title: 'Submit',
            type: 'primary',
            onClick: handleCaseCode,
          },
          {
            title: 'Cancel',
            type: 'ghost',
            onClick: handleCancel,
          },
        ]}
      />
      <JobPostingsUpdate />
    </div>
  );
}
