import styles from './Tooltip.module.scss';
import { IconButton } from '@components/button';
import Text from '@components/text';

function Tooltip(props) {
  const { step, tooltipProps, primaryProps, closeProps, isLastStep } = props;
  return (
    <div {...tooltipProps}>
      <div className={styles.tooltipContainer}>
        <div className={styles.titleRow}>
          {step.title && <Text type="BodySmallMedium">{step.title}</Text>}
          <IconButton
            icon="crossMark"
            viewBox="0 0 16 16"
            type="iconOnly"
            {...closeProps}
            title={null}
          />
        </div>
        {step.content}
        {!isLastStep && (
          <div className={styles.tooltipFooter}>
            <IconButton
              icon="next"
              type="ghost"
              iconAtRight
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
              }}
              {...primaryProps}
              tabIndex={-1}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Tooltip;
