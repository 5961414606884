import { DAGRun, ProjectStatus } from '@common/constants';
import { env } from '@common/env';
import { showErrorToast } from '@components/Toast';
import { useStateContext } from '@state';
import { getAccessToken, useAuthContext } from '@state/auth';
import { useBackgroundTasksContext } from '@state/backgroundTasks';
import {
  getBenchmarkOrganizations,
  getProjects,
  getTemplates,
} from '@util/api';
import { isEmptyObj } from '@util/object';
import Pusher from 'pusher-js';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';

const getPusherChannelName = projectId => {
  if (projectId) {
    const formattedProjectId = projectId.replaceAll('-', '_').toUpperCase();
    const channelName = `AURA_DILIGENCE_TENANT__${formattedProjectId}_DAG_RUNS`;
    return channelName;
  }
  return null;
};

const DiligenceContext = createContext();
const useDiligenceContext = () => useContext(DiligenceContext);

const getAuraCompanyIds = data =>
  data
    .flatMap(item =>
      item?.companies?.map(company => company.company_data.aura_company_id)
    )
    .filter(v => v);

function DiligenceContextProvider({ children }) {
  const { selectedProject, setSelectedProject } = useStateContext();
  const {
    monitorAirflowDAGRun,
    clearProjectDAGRuns,
    clearAllDAGRuns,
    removeDAGRun,
  } = useBackgroundTasksContext();
  const { firstTimeUser, setCanShowWalkThrough, user } = useAuthContext();
  const [cumulioAuthToken, setCumulioAuthToken] = useState('');
  const [cumulioAuthKey, setCumulioAuthKey] = useState('');
  const [projects, setProjects] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [organizations, _setOrganizations] = useState([]);
  const [auraCompanyIds, setAuraCompanyIds] = useState([]);
  const [dashboardSections, setDashboardSections] = useState([]);
  const [pendingRoleMappings, setPendingRoleMappings] = useState({});
  const [rolesTableNeedRefresh, setRolesTableNeedRefresh] = useState(false);
  const pusherRef = useRef();

  const match = useRouteMatch('/insights-hub/:projectId');
  const projectsRoute = useRouteMatch('/insights-hub');
  const userHelpdeskMail = getUserHelpdeskMail(checkIsBainUser(user.email));

  // Use this dictionary to display the DAG name and status in the UI
  // The key is the dag_id and the value is the config object
  const diligenceDAGConfig = {
    tenant_project_role_mapping_update_dag: {
      description: 'Performing custom role mapping',
      blockUserAccess: false,
    },
    manual_custom_role_map_dag: {
      description: 'Uploading custom role mapping',
      blockUserAccess: false,
      callback: () => {
        setRolesTableNeedRefresh(true);
      },
    },
    create_tenant_project_dag: {
      description: 'Creating project',
      blockUserAccess: true,
    },
    delete_tenant_company_dag: {
      description: 'Updating companies list',
      blockUserAccess: true,
    },
    migrate_archived_schema: {
      description: 'Unarchiving project',
      blockUserAccess: true,
      callback: () => {
        setSelectedProject(project => ({
          ...project,
          status: ProjectStatus.ACTIVE,
        }));
        setProjects(projects =>
          projects.map(project =>
            project.id === selectedProject.id
              ? {
                  ...project,
                  status: ProjectStatus.ACTIVE,
                }
              : project
          )
        );
      },
    },
    clone_tenant_project_dag: {
      description: 'Cloning project',
      blockUserAccess: true,
      isClone: true,
    },
    drop_tenant_project_dag: {
      description: 'Deleting project',
      blockUserAccess: true,
    },
  };

  useEffect(() => {
    pusherRef.current = new Pusher(String(env('PUSHER_APP_KEY')), {
      cluster: 'us2',
    });
    // eslint-disable-next-line no-unused-vars
    pusherRef.current.connection.bind('error', function(pushError) {
      // Removed console.error for AUR-72 but we might want to do something with it in the future
    });
    return () => {
      pusherRef.current.disconnect();
      clearAllDAGRuns();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCumulioAuthToken(localStorage.getItem('cumulioAuthToken'));
    setCumulioAuthKey(localStorage.getItem('cumulioAuthKey'));

    if (getAccessToken()) {
      (async () => {
        const [projectData, templateData] = await Promise.all([
          getProjects(),
          getTemplates(),
        ]);
        setProjects(projectData);
        setTemplates(templateData);
        if (firstTimeUser) {
          setCanShowWalkThrough(true);
        }
      })();
    }
  }, [firstTimeUser, setCanShowWalkThrough]);

  useEffect(() => {
    if (projectsRoute?.isExact && projects?.length) {
      (async () => {
        const allProjectsNew = await getProjects();
        setProjects(allProjectsOld =>
          allProjectsNew.map(projectNew => {
            const project = allProjectsOld.find(
              projectOld => projectOld.id === projectNew.id
            );
            return project
              ? {
                  ...projectNew,
                  status: project.status,
                }
              : projectNew;
          })
        );
      })();
    }
  }, [projects?.length, projectsRoute?.isExact]);

  useEffect(() => {
    if (!selectedProject || isEmptyObj(selectedProject)) {
      const project = projects?.find(
        ({ id }) => id === match?.params?.projectId
      );
      if (project) {
        if (selectedProject.id !== project.id) {
          const channelName = getPusherChannelName(selectedProject.id);
          if (channelName) {
            pusherRef.current?.unsubscribe(channelName);
            clearProjectDAGRuns(selectedProject.id);
          }
        }

        setSelectedProject(project);
      }
    }
  }, [
    match?.params?.projectId,
    projects,
    selectedProject,
    setSelectedProject,
    clearProjectDAGRuns,
  ]);

  useEffect(() => {
    if (
      cumulioAuthKey &&
      cumulioAuthKey !== localStorage.getItem('cumulioAuthKey')
    ) {
      localStorage.setItem('cumulioAuthKey', cumulioAuthKey);
    }
    if (
      cumulioAuthToken &&
      cumulioAuthToken !== localStorage.getItem('cumulioAuthToken')
    ) {
      localStorage.setItem('cumulioAuthToken', cumulioAuthToken);
    }
  }, [cumulioAuthKey, cumulioAuthToken]);

  useEffect(() => {
    (async () => {
      if (selectedProject.id) {
        localStorage.setItem(
          'selectedProject',
          JSON.stringify(selectedProject)
        );
        const orgs = await getBenchmarkOrganizations(selectedProject.id);
        setOrganizationsAndRefreshCompanyIds(orgs);
        localStorage.setItem('benchmarkOrganizations', JSON.stringify(orgs));
      }
    })();
    //eslint-disable-next-line
  }, [selectedProject.id]);

  useEffect(() => {
    const channelName = getPusherChannelName(selectedProject.id);
    if (pusherRef.current && channelName) {
      const pusherChannel = pusherRef.current?.subscribe(channelName);
      pusherChannel.bind('AURA_DILIGENCE_DAG_UPDATE', dagRun => {
        const dagConfig = diligenceDAGConfig[dagRun.dag_id];
        if ([DAGRun.SUCCESS, DAGRun.FAILURE].includes(dagRun.state)) {
          removeDAGRun(dagRun.dag_run_id);
          if (dagRun.state === DAGRun.FAILURE) {
            const errorMessage = (
              <p>
                Sorry, {dagConfig.description} failed.
                <br />
                Please try again and if the issue persists, reach out to{' '}
                <a href={`mailto:` + userHelpdeskMail}>{userHelpdeskMail}</a>
              </p>
            );
            showErrorToast(errorMessage, dagRun.dag_id, 10);
          } else if (dagRun.state === DAGRun.SUCCESS && dagConfig?.callback) {
            dagConfig.callback();
          }
        } else {
          const project = projects?.find(({ id }) =>
            id.includes(dagRun.tenant_id)
          );
          monitorAirflowDAGRun({
            ...dagConfig,
            ...dagRun,
            name: project?.name || dagRun.tenant_id,
          });
        }
      });
    }
    //eslint-disable-next-line
  }, [selectedProject.id]);

  const setOrganizationsAndRefreshCompanyIds = orgs => {
    _setOrganizations(orgs);

    if (orgs && orgs?.length > 0) {
      setAuraCompanyIds(getAuraCompanyIds(orgs));
    }
  };

  return (
    <DiligenceContext.Provider
      value={{
        cumulioAuthToken,
        cumulioAuthKey,
        setCumulioAuthToken,
        setCumulioAuthKey,
        projects,
        setProjects,
        templates,
        organizations,
        setOrganizations: setOrganizationsAndRefreshCompanyIds,
        dashboardSections,
        setDashboardSections,
        pendingRoleMappings,
        setPendingRoleMappings,
        rolesTableNeedRefresh,
        setRolesTableNeedRefresh,
        pusherRef,
        auraCompanyIds,
      }}
    >
      {children}
    </DiligenceContext.Provider>
  );
}

export { DiligenceContextProvider, useDiligenceContext };
