import { TrialExpiredLogo } from '@components/brand/';
import { Button } from '@components/button';
import Text from '@components/text';
import styles from './styles/Trial.module.scss';

const TrialExpired = () => {
  const handleClick = () => {
    window.open('https://www.getaura.ai/contact-us', '_blank');
  };
  return (
    <div
      className={styles.alignCenter}
      style={{
        gap: '3em',
        height: 'calc(100vh - 48px)',
      }}
    >
      <div>
        <TrialExpiredLogo />
      </div>
      <div className={styles.alignCenter} style={{ gap: '1.5em' }}>
        <Text type="H4Semibold" textAlign="center">
          Your 14-day free trial has expired!
        </Text>
        <div>
          <Text type="CaptionRegular" textAlign="center">
            You no longer have access to the Aura Platform.
          </Text>
          <Text type="CaptionRegular" textAlign="center">
            Let's change that.
          </Text>
        </div>
        <Text type="CaptionSemibold" textAlign="center">
          To continue using Aura and get unlimited insights, you will need to
          upgrade your plan.
        </Text>
        <Text type="CaptionRegular" textAlign="center">
          Please check out our
          <a
            href=" https://www.getaura.ai/pricing"
            target="_blank"
            style={{
              color: '#ce0b80',
              marginLeft: '5px',
              textDecoration: 'underline',
            }}
          >
            Pricing
          </a>
          .
        </Text>
      </div>
      <Button
        type={'primary'}
        title="Contact Sales"
        style={{ margin: '0 auto' }}
        onClick={handleClick}
      />
    </div>
  );
};

export default TrialExpired;
