import { Button } from '@components/button';
import Text from '@components/text';
import React from 'react';

import { useAuthContext } from '../../state/auth';
import styles from './styles/Trial.module.scss';

const UpperHeader = () => {
  const { expiredIn } = useAuthContext();
  const handleClick = () => {
    window.open('https://www.getaura.ai/contact-us', '_blank');
  };

  return (
    <div className={styles.trialHeader}>
      <Text type="MicroMedium">
        Your free trial will end in :
        <span style={{ marginLeft: '5px' }}>{expiredIn}.</span>
      </Text>
      <Button
        id="contact-sales"
        type="secondary"
        compact={true}
        title="Contact Sales"
        onClick={handleClick}
        style={{
          padding: '3px 8px',
          fontSize: '10px',
          marginLeft: '10px',
        }}
      />
    </div>
  );
};

export default UpperHeader;
