import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { DAGRun } from '@common/constants';

const BackgroundTasksContext = createContext();
const useBackgroundTasksContext = () => useContext(BackgroundTasksContext);

function BackgroundTasksContextProvider({ children }) {
  const [tasks, setTasks] = useState([]);
  const [airflowDAGRuns, setAirflowDAGRuns] = useState([]);
  const isRunning = useRef(false);
  const addTask = useCallback(
    newTask => {
      const incompleteTasks = tasks.filter(task => !task.done);
      setTasks([...incompleteTasks, newTask]);
    },
    [tasks]
  );
  const clearTasks = useCallback(() => setTasks([]), []);

  const removeDAGRun = useCallback(
    dagRunId => {
      const filteredDAGRuns = airflowDAGRuns.filter(
        dagRun => dagRun.dag_run_id !== dagRunId
      );
      setAirflowDAGRuns(filteredDAGRuns);
    },
    [airflowDAGRuns]
  );

  const monitorAirflowDAGRun = useCallback(
    newDAGRun => {
      if (newDAGRun.fakeDAGRun) {
        // remove fake DAGRun after 5 minutes if exists in the list
        setTimeout(() => {
          removeDAGRun(newDAGRun.dag_run_id);
        }, 30 * 60 * 1000);
      }

      const activeDAGRuns = airflowDAGRuns.filter(dagRun => {
        if (![DAGRun.SUCCESS, DAGRun.FAILURE].includes(dagRun.state)) {
          return false;
        } else {
          // removes fake dag runs added to start progress indicator immediately
          return !(
            dagRun.tenant_id === newDAGRun.tenant_id &&
            dagRun.dag_id.includes(newDAGRun.dag_id)
          );
        }
      });
      setAirflowDAGRuns([...activeDAGRuns, newDAGRun]);
    },
    [airflowDAGRuns, removeDAGRun]
  );

  const clearProjectDAGRuns = useCallback(
    projectId => {
      if (projectId) {
        const filteredDAGRuns = airflowDAGRuns.filter(
          dagRun => dagRun.tenant_id !== projectId
        );
        setAirflowDAGRuns(filteredDAGRuns);
      }
    },
    [airflowDAGRuns]
  );

  const clearAllDAGRuns = useCallback(() => setAirflowDAGRuns([]), []);

  useEffect(() => {
    const incompleteTasks = tasks.filter(task => !task.done);
    if (incompleteTasks.length && !isRunning.current) {
      isRunning.current = true;
      (async () => {
        let updatedTasks = [];
        try {
          // task() should return a promise, which in turn executes block of code
          // the resolved object gets merged into the task object and
          // tasks are considered as done if resolved object has { done: true }
          if (incompleteTasks.length) {
            const taskPromises = incompleteTasks.map(({ task, ...rest }) =>
              task(rest)
            );
            const taskResults = await Promise.all(taskPromises);
            updatedTasks = taskResults.map((result, index) => {
              return { ...incompleteTasks[index], ...result };
            });
          }
        } finally {
          isRunning.current = false;
          setTimeout(
            () =>
              setTasks(tasks =>
                tasks.map(
                  task => updatedTasks.find(({ id }) => id === task.id) || task
                )
              ),
            3000
          );
        }
      })();
    }
  }, [tasks]);

  // use addTask to add a task to the list
  return (
    <BackgroundTasksContext.Provider
      value={{
        tasks,
        addTask,
        clearTasks,
        airflowDAGRuns,
        monitorAirflowDAGRun,
        clearProjectDAGRuns,
        clearAllDAGRuns,
        removeDAGRun,
      }}
    >
      {children}
    </BackgroundTasksContext.Provider>
  );
}

const isProcessingBlockingData = ({ activeDAGRuns = [], projectId }) =>
  activeDAGRuns
    .filter(dagRun => projectId.includes(dagRun.tenant_id))
    .some(dagRun => dagRun.blockUserAccess);

const isProcessingData = ({ activeDAGRuns = [], projectId }) =>
  activeDAGRuns
    .filter(dagRun => projectId.includes(dagRun.tenant_id))
    .some(dagRun => dagRun.state === 'started' || dagRun.state === 'queued');

export {
  useBackgroundTasksContext,
  BackgroundTasksContextProvider,
  isProcessingBlockingData,
  isProcessingData,
};
