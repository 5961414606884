export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function round(number, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces || 0);
  return Math.round(number * multiplier) / multiplier;
}

export function percent(
  progress,
  total,
  decimals = { allow: false, positions: 2 }
) {
  progress = progress || 0;
  total = total || 0;

  if (decimals.allow) {
    const float = ((progress / total) * 100).toFixed(decimals.positions);
    return parseFloat(float);
  }

  return total === 0 ? 0 : Math.round((progress / total) * 100);
}

export const range = (min, max) =>
  [...Array(max - min + 1).keys()].map(i => i + min);

export const hasDecimal = value => {
  let number = value;
  if (typeof number === 'string') {
    number = parseFloat(value);
  }
  let regExPattern = /^-?\d+\.\d+$/;

  return regExPattern.test(number);
};
