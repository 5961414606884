export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEV;
export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_STAGE = process.env.NODE_ENV === 'stage';

export const isDev = () =>
  ['localhost', 'dev.getaura.ai'].includes(window.location.hostname);

export const isProd = () =>
  ['app.getaura.ai'].includes(window.location.hostname);

export const isStage = () =>
  ['stage.getaura.ai'].includes(window.location.hostname);

export const getBucketName = hostname => {
  if (['stage.getaura.ai'].includes(hostname)) {
    return 'aura-rolemapping-uploads-stage';
  } else if (['dev.getaura.ai'].includes(hostname)) {
    return 'aura-rolemapping-uploads-getaura-dev';
  } else if (['app.getaura.ai'].includes(hostname)) {
    return 'aura-rolemapping-uploads-getaura';
  } else {
    return '';
  }
};

export const currentEnv = () => {
  if (isDev()) return 'dev';
  if (isProd()) return 'prod';
  if (isStage()) return 'stage';
  return 'test';
};
