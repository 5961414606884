import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal } from '@components/modal';
import { track, Event } from '@common/mixpanel';
import { useBackgroundTasksContext } from '@state/backgroundTasks';

import styles from './IncompleteDownloadsAlert.module.scss';

export default function IncompleteDownloadsAlert({ onClose }) {
  const { push } = useHistory();
  const { clearTasks } = useBackgroundTasksContext();
  return (
    <div style={{ zIndex: 21 }}>
      <Modal
        className={styles.incompleteDownloadsAlert}
        containerStyle={{ maxWidth: '20rem', maxHeight: '25rem' }}
        headerText={
          <div className={styles.title}>
            <p>Download in progress</p>
          </div>
        }
        footerButtonPropsList={[
          {
            title: 'Sign out',
            type: 'primary',
            onClick: () => {
              clearTasks();
              setTimeout(() => {
                push('/logout');
                track(Event.LOGOUT);
              }, 100);
            },
          },
          { title: 'Stay back', type: 'ghost', onClick: onClose },
        ]}
        footerClassName={styles.footer}
        contentClassName={styles.content}
        isVisible={true}
        onClose={onClose}
        contentComponent={
          <div className={styles.content}>
            <p>
              Moving away from this page would result in current download
              process to stop. Do you want to proceed nonetheless?
            </p>
          </div>
        }
      />
    </div>
  );
}

IncompleteDownloadsAlert.propTypes = {
  onClose: PropTypes.func,
};
