import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Tasks from '../tasks';
import { useStateContext } from '@state';
import ProgressBar from '@components/ProgressBar';
import styles from './styles/BackgroundTasksUI.module.scss';
import { Button } from '@components/button';
import colors from '@styles/colors';
import { Spinner } from '@components/loader';
import {
  useBackgroundTasksContext,
  isProcessingBlockingData,
} from '@state/backgroundTasks';
import { ProjectStatus } from '@common/constants';

function WaitingScreen({ message, style, showBackButton = false }) {
  const history = useHistory();
  return (
    <div className={styles.loadingScreen} style={style}>
      {showBackButton && (
        <Button
          title="Return to Projects Home"
          type="secondary"
          style={{
            position: 'absolute',
            top: '80px',
            left: '50px',
            border: '1px solid var(--gray-200)',
            color: colors.gray900,
          }}
          icon="backArrow"
          viewBox="0 0 16 16"
          onClick={() => {
            history.goBack();
          }}
        />
      )}
      <div className={styles.text}>
        {message}
        <div>
          <ProgressBar isInfinite />
        </div>
      </div>
    </div>
  );
}

WaitingScreen.propTypes = {
  message: PropTypes.object,
  progress: PropTypes.number,
  showBackButton: PropTypes.bool,
  style: PropTypes.object,
};

export default function BackgroundTasksUI() {
  const { selectedProject } = useStateContext();
  const { tasks, airflowDAGRuns } = useBackgroundTasksContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const projectURLMatch = useRouteMatch('/insights-hub/:projectId');
  const incompleteTasks = tasks?.filter(task => !task.done);

  let totalPercent = 0;
  let overallProgress = 0;
  if (incompleteTasks?.length) {
    incompleteTasks.forEach(task => {
      const taskProgress =
        task.jobs?.[0].percentage_complete ?? task.percentage_complete;
      totalPercent += taskProgress;
    });
    overallProgress = totalPercent / incompleteTasks.length;
  }

  useEffect(() => {
    if (!incompleteTasks.length && !airflowDAGRuns.length) {
      setIsModalVisible(false);
    }
  }, [incompleteTasks, airflowDAGRuns.length]);

  if (projectURLMatch?.isExact && selectedProject?.id) {
    const blockUserAccess = isProcessingBlockingData({
      activeDAGRuns: airflowDAGRuns,
      projectId: selectedProject.id,
    });
    if (
      !selectedProject.is_template_project &&
      !selectedProject.status &&
      !blockUserAccess
    ) {
      return (
        <div className={styles.loadingScreen}>
          <Spinner height={'80px'} width={'80px'} />
        </div>
      );
    }

    if (selectedProject?.status === ProjectStatus.INACTIVE) {
      return (
        <WaitingScreen
          message={
            <>
              <div>Looks like you’ve been away a while!</div>
              <div>
                Grab a coffee while we update your data{' '}
                <span role="img" aria-label="Chart">
                  📊
                </span>
              </div>
            </>
          }
          style={{ backgroundColor: colors.gray100 }}
          showBackButton
        />
      );
    }

    if (blockUserAccess) {
      return (
        <WaitingScreen
          message={
            <>
              <div>Your data is being prepared.</div>
              <div>This usually takes up to 5 minutes.</div>
              <div>
                Grab a coffee!{' '}
                <span role="img" aria-label="Coffee">
                  ☕
                </span>
              </div>
            </>
          }
        />
      );
    }
  }

  if (airflowDAGRuns.length || incompleteTasks.length) {
    return (
      <>
        {isModalVisible ? (
          <Tasks
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            tasks={incompleteTasks}
            airflowDAGRuns={airflowDAGRuns}
          />
        ) : null}
        <div
          className={styles.container}
          onClick={() => setIsModalVisible(true)}
        >
          <div className={styles.modalDiv}>
            <div className={styles.modalTitleDiv}>
              <div className={styles.jobsRunningDiv}>
                <div className={styles.jobsRunning}>
                  {incompleteTasks.length || airflowDAGRuns.length || 0}
                </div>
              </div>
              <div className={styles.modalTitle}>Processing data...</div>
            </div>
          </div>
          <div>
            <ProgressBar
              percent={overallProgress}
              isInfinite={airflowDAGRuns.length}
            />
          </div>
        </div>
      </>
    );
  }

  return null;
}
