import { createContext, useContext, useState } from 'react';

const TalentPoolContext = createContext();
const useTalentPoolContext = () => useContext(TalentPoolContext);

function TalentPoolContextProvider({ children }) {
  const [sharedProps, setSharedProps] = useState({});

  return (
    <TalentPoolContext.Provider
      value={{
        sharedProps,
        setSharedProps,
      }}
    >
      {children}
    </TalentPoolContext.Provider>
  );
}

export { useTalentPoolContext, TalentPoolContextProvider };
