import React from 'react';
import { Modal } from 'antd';
import Text from '@components/text';
import Icon from '@components/icon';
import { Button } from '@components/button';

import './styles/antd-overrides.scss';

let prevErrorMessage = null;

const showSessionExpired = (errorMessage, key) => {
  if (prevErrorMessage === errorMessage && key) {
    Modal.destroyAll();
  }
  prevErrorMessage = errorMessage;
  Modal.error({
    content: (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            rowGap: '12px',
          }}
        >
          <Icon icon="warning" viewBox="2 5 20 14" fill="none" size="large" />
          <Text type="SubheadingSemibold" style={{ marginBottom: '8px' }}>
            Session Expired
          </Text>
        </div>
        <Text
          type="CaptionRegular"
          style={{ color: 'var(--gray-600)', margin: '6px 0px 18px 0px' }}
        >
          {errorMessage}
        </Text>
        <Button
          type={'primary'}
          title="Refresh"
          onClick={() => {
            location.reload();
          }}
          style={{ width: '100%', margin: 'auto 0' }}
        />
      </div>
    ),
    centered: true,
    maskClosable: false,
    okButtonProps: { style: { display: 'none' } },
    cancelButtonProps: { style: { display: 'none' } },
    className: 'errorModal',
    icon: null,
    closable: false,
    style: { borderRadius: '8px' },
  });
};

export { showSessionExpired };