import { Subscriptions } from '@common/constants';
import { successModal } from '../components/UpdatesModal';
import { triallUserMsg } from './constants';

export const SubscriptionModuleNames = {
  InsightsHub: 'insights_hub',
  Sourcing: 'sourcing',
  LeadFinder: 'lead_finder',
};

export const canShowPremiumLabelFor = (moduleName, subscriptions) =>
  subscriptions.some(item => {
    if (Object.keys(item).includes(moduleName)) {
      const subscription = item[moduleName];
      return Subscriptions.indexOf(subscription) < 3;
    }
    return false;
  });

export const checkTrialAccess = (showModal, msg) => {
  if (showModal) {
    const message = msg || triallUserMsg.download_msg;
    successModal({
      message,
      heading: 'Access Restricted',
      icon: 'warning',
    });
    return true;
  }
  return false;
};
