import {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { __RouterContext } from 'react-router';
import classNames from 'classnames';

export function useStyleToggle(
  activeElementId,
  elementId,
  style = {
    borderRadius: '6px',
    boxShadow: '0 0 10px var(--violet)',
  }
) {
  const [containerStyle, setContainerStyle] = useState({});
  let timeoutRef = useRef(null);
  let elementRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if (activeElementId === elementId && elementRef.current) {
      setContainerStyle(style);

      timeoutRef.current = setTimeout(() => {
        setContainerStyle({});
      }, 800);

      return () => {
        clearTimeout(timeoutRef.current);
      };
    } else {
      elementRef.current = activeElementId;
    }
  }, [activeElementId, elementId, style, setContainerStyle]);

  return containerStyle;
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export function useIsMounted() {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return useCallback(() => mountedRef.current, []);
}

export function useHasEnteredViewport(ref) {
  const [isInViewport, setIsInViewport] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (!isInViewport && entry.isIntersecting) {
          return setIsInViewport(true);
        }
        if (isInViewport) {
          return observer.disconnect();
        }
      }),
    [isInViewport, setIsInViewport]
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isInViewport;
}

// Legacy hooks
export const useRenderOnResize = () => {
  const [bounds, setBounds] = useState(
    JSON.stringify({ width: window.innerWidth, height: window.innerHeight })
  );

  useEffect(() => {
    const listener = () => {
      const newBounds = JSON.stringify({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (newBounds !== bounds) {
        setBounds(newBounds);
      }
    };

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [bounds]);
};

export const useForceUpdate = () => {
  const [, setRand] = useState(Math.random());
  return () => setRand(Math.random());
};

export const useResponsiveSizes = () => {
  const getSizes = w => ({
    deviceXs: w > 0,
    deviceXsOnly: w < 768,
    deviceSm: w >= 768,
    deviceSmOnly: w >= 768 && w < 992,
    deviceMd: w >= 992,
    deviceMdOnly: w >= 992 && w < 1200,
    deviceLg: w >= 1200,
  });

  const [sizes, setSizes] = useState(
    getSizes(window.innerWidth, window.innerHeight)
  );

  useEffect(() => {
    const listener = () => {
      const newSizes = getSizes(window.innerWidth, window.innerHeight);
      if (JSON.stringify(newSizes) !== JSON.stringify(sizes)) {
        setSizes(newSizes);
      }
    };
    window.addEventListener('resize', listener);
    // run the first time
    listener();
    return () => window.removeEventListener('resize', listener);
  }, [sizes]);

  return sizes;
};

let rootClasses = {};

export const useRootClass = classes => {
  useEffect(() => {
    rootClasses = { ...rootClasses, ...classes };
    const el = document.documentElement;
    el.setAttribute('class', classNames(rootClasses));
  });
};

export const useMatch = () => {
  const match = useContext(__RouterContext).match;
  if (match.path.endsWith('/')) {
    match.path = match.path.slice(0, -1);
  }
  return match;
};

export const useBoolean = initialValue => {
  const [bool, setBool] = useState(initialValue);

  const setTrue = useCallback(() => {
    setBool(true);
  }, []);

  const setFalse = useCallback(() => {
    setBool(false);
  }, []);

  return [bool, setTrue, setFalse];
};

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export const useLocalStorage = (key, defaultValue) => {
  const [data, setData] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    try {
      const existingData = window.localStorage.getItem(key);
      return existingData ? JSON.parse(existingData) : defaultValue;
    } catch {
      return defaultValue;
    }
  });

  const save = useCallback(
    value => {
      if (typeof window !== 'undefined') {
        const valueToSave = value ?? defaultValue;
        const valueAsString = JSON.stringify(valueToSave);
        window.localStorage.setItem(key, valueAsString);
        setData(valueToSave);
      }
    },
    [defaultValue, key]
  );
  const remove = useCallback(() => window.localStorage.removeItem(key), [key]);

  const onStorageEvent = useCallback(
    e => {
      if (
        e.storageArea === window.localStorage &&
        e.key === key &&
        e.newValue
      ) {
        setData(JSON.parse(e.newValue));
      }
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener('storage', onStorageEvent);
    return () => window.removeEventListener('storage', onStorageEvent);
  }, [onStorageEvent, key]);

  return [data, save, remove];
};

export const useBeforeUnload = (shouldPrompt = true) => {
  const [isDirty, setDirty] = useState(false);

  const onBeforeUnload = useCallback(
    event => {
      if (shouldPrompt && isDirty) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    },
    [isDirty, shouldPrompt]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [onBeforeUnload]);

  return setDirty;
};

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
