import auraUrl from './AuraAppLogo.svg';
import logoWthBain from './logo-with-bain.png';
import TrialExpiredLogoImg from './TrialExpiredLogo.svg';

export function BrandLogo() {
  return <img src={auraUrl} alt="Aura" style={{ height: '100%' }} />;
}

export function BrandLogoWithBain() {
  return <img src={logoWthBain} alt="Aura" style={{ height: '100%' }} />;
}

export function TrialExpiredLogo() {
  return <img src={TrialExpiredLogoImg} alt="Aura" style={{ height: '100%' }} />;
}
