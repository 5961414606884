import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const StateContext = createContext({});
const useStateContext = () => useContext(StateContext);

export default function StateContextProvider({ children }) {
  const [selectedProject, setSelectedProject] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [
    showIncompleteDownloadsAlert,
    setShowIncompleteDownloadsAlert,
  ] = useState(false);
  const [showShareProjectModal, setShareProjectModalVisible] = useState(false);
  const [cubeToken, setCubeToken] = useState(null);
  const [isCorporateClient, setIsCorporateClient] = useState(false);
  const [corporateModule, setCorporateModule] = useState(false);

  const value = {
    selectedProject,
    setSelectedProject,
    breadcrumbs,
    setBreadcrumbs,
    hiddenColumns,
    setHiddenColumns,
    showPremiumModal,
    setShowPremiumModal,
    showIncompleteDownloadsAlert,
    setShowIncompleteDownloadsAlert,
    showShareProjectModal,
    setShareProjectModalVisible,
    cubeToken,
    setCubeToken,
    isCorporateClient,
    setIsCorporateClient,
    corporateModule,
    setCorporateModule,
  };

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
}

StateContextProvider.propTypes = {
  children: PropTypes.node,
};

export { StateContext, StateContextProvider, useStateContext };
