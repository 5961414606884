import { useEffect } from 'react';
import { useAuthContext } from '@state/auth';
import { useHistory } from 'react-router';

export function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    } else {
      var redirect = history.location.search.includes('?screen_hint=signup')
        ? { screen_hint: 'signup', redirect_uri: window.location.origin }
        : {};
      loginWithRedirect(redirect);
    }
  });

  return null;
}

export function Logout() {
  const { logout } = useAuthContext();

  useEffect(() => {
    logout();
  });

  return null;
}
