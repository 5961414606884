export default {
  eggplant: '#a80867',
  white: 'white',
  violet: '#ce0b80',
  lightBlue: '#cfe8fb',

  // gradients
  primary: 'linear-gradient(135deg, #de0788 0%, #a11c66 100%)',
  secondary: 'linear-gradient(135deg, #d90b0b 0%, #a80867 100%)',
  tertiary: 'linear-gradient(135deg, #e36933 0%, #a80867 100%)',

  // ui support palette
  success: '#20BF73',
  warning: '#FFD130',
  dannger: '#D90B0B',

  // grayscale
  gray050: '#fcfcfd',
  gray100: '#f7f8fb',
  gray200: '#e3ebf0',
  gray300: '#c2d1d9',
  gray400: '#7d8f9b',
  gray500: '#667c8a',
  gray600: '#5a6d79',
  gray700: '#495a63',
  gray800: '#3a474e',
  gray900: '#263238',
};
