import { Event, track } from '@common/mixpanel';
import { Button } from '@components/button';
import Dropdown from '@components/dropdown/Dropdown';
import Icon from '@components/icon';
import { Input } from '@components/input';
import { Modal } from '@components/modal';
import Text from '@components/text';
import { useStateContext } from '@state';
import { deleteUser, getUser, patchUser, postUser } from '@util/api';
import { getDomain } from '@util/email';
import { useIsMounted } from '@util/hooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './styles/ShareProjectModal.module.scss';

export default function ShareProjectModal({ setIsModalVisible }) {
  const isMounted = useIsMounted();
  const { selectedProject } = useStateContext();
  const [email, setEmail] = useState('');
  const [role, setUserType] = useState('editor');
  const [userList, setUserList] = useState([]);
  const [remove, setRemove] = useState(false);

  useEffect(() => {
    if (selectedProject?.id) {
      (async () => {
        if (isMounted) {
          const result = await getUser(selectedProject.id);
          const jsonResult = await result.json();
          setUserList(jsonResult);
        }
      })();
    }
  }, [isMounted, remove, selectedProject]);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const userTypeHandleChange = value => {
    setUserType(value);
  };

  const handleOk = () => {
    postUser(selectedProject.id, {
      email_address: email.toLowerCase(),
      role: role.substring(0, 1).toUpperCase() + role.substring(1),
    }).then(() => {
      setRemove(!remove);
    });
    setEmail('');

    track(Event.INSIGHTS_PROJECT_SHARED_EVENT, {
      'Project ID': selectedProject.id,
      companies: selectedProject.benchmark_organizations,
      "Shared user's email domain": getDomain(email),
      Invitee: email,
    });
  };

  const handleRoleChangeUpdate = (username, data) => {
    if (data.role === 'Remove') {
      deleteUser(username, selectedProject.id).then(() => {
        setRemove(!remove);
      });
    } else {
      patchUser(username, selectedProject.id, data);
      var newUserList = [];
      for (const user of userList) {
        if (user.username === username) {
          var newUserData = user;
          newUserData.role = data.role;
          newUserList.push(newUserData);
        } else {
          newUserList.push(user);
        }
      }
      setUserList(newUserList);
    }
  };

  return (
    <Modal
      className={styles.createProjectModal}
      headerText={<div className={styles.modalTitle}>Share project</div>}
      hasCloseButton={false}
      centered
      footerButtonPropsList={[]}
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.9)',
      }}
      width="680"
      isVisible={true}
      onClose={handleCancel}
      contentComponent={
        <div className={styles.content}>
          <Text type="CaptionRegular">Invitee email</Text>
          <div className={styles.emailInvite}>
            <Input
              placeholder="Enter email..."
              compact={true}
              leftComponent={<Icon icon="addUser" />}
              onChange={evt => setEmail(evt.target.value)}
              value={email}
              disableClickFocus={true}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleOk();
                }
              }}
              rightComponent={
                <Dropdown
                  selectedKey={role.toLowerCase()}
                  compact={true}
                  hasButtonOutline={false}
                  items={[
                    {
                      key: 'owner',
                      label: 'Owner',
                      subtitle:
                        'Can view and edit companies / roles, invite / \n remove  users, as well as create / delete projects',
                    },
                    {
                      key: 'editor',
                      label: 'Editor',
                      subtitle:
                        'Can view and edit companies / role, \n and invite users',
                    },
                    {
                      key: 'viewer',
                      label: 'Viewer',
                      subtitle:
                        'Can only view content, but is not able to \n edit or create any companies, roles, or users',
                    },
                  ]}
                  onChange={selectedRole => {
                    userTypeHandleChange(selectedRole);
                  }}
                  isWithinModal
                />
              }
            />
            <Button
              title="Send Invite"
              disabled={!validateEmail(email)}
              style={{ height: '42px', flexShrink: '0' }}
              onClick={() => handleOk()}
            />
          </div>
          <div className={styles.supportiveText}>Type a valid email</div>
          <div className={styles.userList}>
            {Array.isArray(userList) &&
              userList.map((user, userIndex) => (
                <div key={userIndex} className={styles.userTile}>
                  <div className={styles.listMailMsg}>
                    <Text type="CaptionMedium">{user.email_address}</Text>
                  </div>
                  <div className={styles.listUserType}>
                    <Dropdown
                      compact={true}
                      hasButtonOutline={false}
                      selectedKey={
                        user && user.role ? user.role : 'select a value'
                      }
                      items={[
                        {
                          key: 'Owner',
                          label: 'Owner',
                          subtitle:
                            'Can view and edit companies / roles, invite / remove \n users, as well as create / delete projects',
                        },
                        {
                          key: 'Editor',
                          label: 'Editor',
                          subtitle:
                            'Can view and edit companies / role, \n and invite users',
                        },
                        {
                          key: 'Viewer',
                          label: 'Viewer',
                          subtitle:
                            'Can only view content, but is not able to \n edit or create any companies, roles, or users',
                        },
                        {
                          key: 'Remove',
                          label: 'Remove',
                          customStyle: { color: '#D90B0B' },
                        },
                      ]}
                      onChange={selectedRole => {
                        handleRoleChangeUpdate(user.username, {
                          email_address: email,
                          role: selectedRole,
                        });
                      }}
                      isWithinModal
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      }
    ></Modal>
  );
}

ShareProjectModal.propTypes = {
  setIsModalVisible: PropTypes.func,
};
