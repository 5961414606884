import React, { useEffect, useState } from 'react';
import { env } from '@common/env';
import { useAuthContext } from '@state/auth';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const clientSideID = String(env('REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID'));

const LDProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (user) {
      const initLDProvider = async () => {
        const ldProvider = await asyncWithLDProvider({
          clientSideID,
          context: {
            kind: 'user',
            key: user.id,
            email: user.email,
          },
        });
        setProvider(() => ldProvider);
      };

      initLDProvider();
    }
  }, [user]);

  if (!provider) {
    return <>{children}</>;
  }

  const ProviderComponent = provider;
  return <ProviderComponent>{children}</ProviderComponent>;
};

export default LDProvider;
