export const isEmptyObj = obj => {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};


const ObjectUtils = (() => {
  const { keys, freeze } = Object;
  let __isObject,
    __merge,
    __coalesceByKey,
    __deepMerge,
    __getMaxDepth,
    __deepKeysFind,
    __arrayDeepKeys,
    __deepKeysFindJoin,
    __deepCopy,
    __deepKeysConcat;
  __isObject = a => typeof a === 'object' && !Array.isArray(a);
  __merge = (a, b) =>
    __isObject(a) && __isObject(b)
      ? __deepMerge(a, b)
      : __isObject(a) && !__isObject(b)
      ? a
      : b;
  __coalesceByKey = src => (acc, key) =>
    (acc[key] && src[key]
      ? (acc[key] = __merge(acc[key], src[key]))
      : (acc[key] = {...src[key]})) && acc;
  __deepMerge = (target, ...sources) =>
    sources.reduce(
      (acc, src) => keys(src).reduce(__coalesceByKey(src), acc),
      target
    );
  __getMaxDepth = (a, startDepth) => {
    let depth = startDepth;
    for (const child of a.children ?? []) {
      depth = Math.max(depth, __getMaxDepth(child, startDepth + 1));
    }
    return depth;
  };
  __arrayDeepKeys = t => {
    let setVal = new Set(__deepKeysFind(t).flat(Infinity));
    setVal.delete('total');
    return [...setVal];
  };
  __deepCopy =(t) => 
    __isObject(t) && JSON.parse(JSON.stringify(t))
  __deepKeysFind = (t, pre = []) =>
    Array.isArray(t)
      ? []
      : Object(t) === t
      ? Object.entries(t).map(([k, v]) => __deepKeysFind(v, [...pre, k]))
      : pre;

  __deepKeysFindJoin = (t, pre = []) =>
    Array.isArray(t)
      ? []
      : Object(t) === t
      ? Object.entries(t).flatMap(([k, v]) =>
          __deepKeysFindJoin(v, [...pre, k])
        )
      : pre.join('.');

  __deepKeysConcat = t => {
    let arrayOfKeys = __deepKeysFindJoin(t);
    let setNoTotal = new Set(arrayOfKeys);
    return [...setNoTotal];
  };
  return freeze({
    isObject: __isObject,
    deepMerge: __deepMerge,
    getMaxDepth: __getMaxDepth,
    getDeepKeys: __arrayDeepKeys,
    getDeepKeysConcat: __deepKeysConcat,
    getDeepCopy: __deepCopy,
  });
})();

export { ObjectUtils };
