import Text from '@components/text';

import styles from './InfoPopOver.module.scss';

const InfoFooter = () => (
  <div className={styles.infoFooter}>
    <Text type="LegendRegular">
      <a
        href="https://auraintel.zendesk.com/hc/en-us"
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        Help Center
      </a>
    </Text>
  </div>
);

const Infos = {
  'Insights Hub': {
    title: 'Insights Hub module',
    content: (
      <div className={styles.infoContent}>
        <Text type="LegendRegular">
          Compare companies side-by-side in minutes. Create high-fidelity talent
          insights for any company and its competitors.
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">
              Is your target attracting top talent, or they losing out to
              competitors?
            </Text>
          </li>
          <li>
            <Text type="LegendRegular">
              Does the talent base have the right skills to gain competitive
              advantage?
            </Text>
          </li>
        </ul>
        <Text type="LegendRegular">
          Discover hidden insights, actionable intelligence and value creation
          opportunities.
        </Text>
        <InfoFooter />
      </div>
    ),
  },
  Sourcing: {
    title: 'Sourcing module',
    content: (
      <div className={styles.infoContent}>
        <Text type="LegendRegular">
          Streamline sourcing by identifying high growth and high potential
          companies using talent data.
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">Who are hiring most FAANG Alumni?</Text>
          </li>
          <li>
            <Text type="LegendRegular">
              Who is experiencing the highest growth of Senior Leadership roles
              in the last 6 months?
            </Text>
          </li>
        </ul>
        <Text type="LegendRegular">
          A new way to find the next big thing powered with talent data.
        </Text>
        <InfoFooter />
      </div>
    ),
  },
  'Lead Finder': {
    title: 'Lead Finder module',
    content: (
      <div>
        <Text type="LegendRegular">
          Find talent and sales leads with unrivaled precision.
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">
              Find a full stack engineer with 5+ years of experience who works
              at a fast growing fintech in Germany.
            </Text>
          </li>
          <li>
            <Text type="LegendRegular">
              Who are the Sales VPs in SaaS B2B companies currently based in
              California?
            </Text>
          </li>
        </ul>
        <Text type="LegendRegular">
          Identify the right individuals to meet the talent needs of your
          business.
        </Text>
        <InfoFooter />
      </div>
    ),
  },
  Companies: {
    title: 'Add companies',
    content: (
      <div className={styles.infoContent}>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">Company name search</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              <p>
                Search companies by typing their names. To allow the best
                result, make sure to do some research on potential rebranding,
                aliases or name variations. For example,{' '}
                <a
                  href="https://auraintel.zendesk.com/hc/en-us"
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  JPMorgan
                </a>{' '}
                (rather than JP Morgan).
              </p>
            </Text>
          </div>
        </div>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">
            Combine relevant subsidiaries into one single organization
          </Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              <p>
                Drag and drop subsidiaries into the same company queue to
                combine relevant subsidiaries into a one single organization.
                For example, combine{' '}
                <a
                  href="https://auraintel.zendesk.com/hc/en-us"
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  GE, GE Healthcare, GE Capital, GE Power
                </a>{' '}
                together into one{' '}
                <a
                  href="https://auraintel.zendesk.com/hc/en-us"
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  GE
                </a>
                .
              </p>
            </Text>
          </div>
        </div>
      </div>
    ),
  },
  'Job Postings': {
    title: 'Job Postings module',
    content: (
      <div>
        <Text type="LegendRegular">
          Unlock insights into companies’ hiring strategy and efficiency.
          Identify the key skills and roles in demand.
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">
              What types of roles are my competitors hiring?
            </Text>
          </li>
          <li>
            <Text type="LegendRegular">
              What are the most sought after skills for Software Developers?
            </Text>
          </li>
        </ul>
        <Text type="LegendRegular">
          Stay ahead and anticipate the future of the job market.
        </Text>
        <InfoFooter />
      </div>
    ),
  },
  Databox: {
    title: 'Databox module',
    content: (
      <div>
        <Text type="LegendRegular">
          Query and download talent data with with ready-to-use filters and fast
          search.
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">
              For United States, which are the top universities from where the
              banking professionals come from and what are their top skills?
            </Text>
          </li>
          <li>
            <Text type="LegendRegular">
              For a company in aviation industry, what's the average tenure of
              talent?
            </Text>
          </li>
        </ul>
        <Text type="LegendRegular">
          Download data and run analysis using your own BI tools.
        </Text>
        <InfoFooter />
      </div>
    ),
  },
  'Talent Pool': {
    title: 'Talent Pool module',
    content: (
      <div>
        <Text type="LegendRegular">
          Create a winning technology talent strategy:
        </Text>
        <ul>
          <li>
            <Text type="LegendRegular">
              Identify your current and future talent needs.
            </Text>
          </li>
          <li>
            <Text type="LegendRegular">Uncover hidden sources of talent.</Text>
          </li>
          <li>
            <Text type="LegendRegular">
              Keep a pulse of market and stay ahead of the curve.
            </Text>
          </li>
        </ul>
        <InfoFooter />
      </div>
    ),
  },
  Dashboards: {
    title: 'Talent insights dashboards',
    content: (
      <div className={styles.infoContent}>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">20+ (and counting) dashboards</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              Explore and benchmark companies on Roles, Growth, Hiring &
              Attrition, Sources & Destinations and much more!
            </Text>
          </div>
        </div>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">Consume your way</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              Enjoy our suite of dashboards, or export relevant charts in CSV.
            </Text>
          </div>
        </div>
      </div>
    ),
  },
  Roles: {
    title: 'Customize roles segmentation',
    content: (
      <div className={styles.infoContent}>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">Roles allocation</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              <p>
                Aura utilizes proprietary auto-role classification system to map
                ‘raw’ job titles into our{' '}
                <a
                  href="https://auraintel.zendesk.com/hc/en-us"
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  standard roles taxonomy
                </a>
                .
              </p>
            </Text>
          </div>
        </div>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">Customization built-in</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              <p>
                Edit our standard roles taxonomy by creating your{' '}
                <a
                  href="https://auraintel.zendesk.com/hc/en-us"
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  custom roles taxonomy
                </a>
                .
              </p>
            </Text>
          </div>
        </div>
        <div className={styles.infoSection}>
          <Text type="CaptionRegular">Quick and easy job titles search</Text>
          <div className={styles.infoSubSection}>
            <Text type="LegendRegular">
              <p>
                We support boolean search, exact match and exclude logics to
                facilitate ‘raw’ job titles search results. This helps speeding
                up necessary fine-tunings and customizations.
              </p>
            </Text>
          </div>
        </div>
      </div>
    ),
  },
  'Share Project': {
    title: 'Share Project',
    content: (
      <div className={styles.infoContent}>
        <Text type="LegendRegular">
          Share the Insights Hub project with team members or directly to clients.
          Work collaboratively to improve roles taxonomy and find hidden
          insights.
        </Text>
      </div>
    ),
  },
  'Export Project Data': {
    title: 'Export Project Data',
    content: (
      <div className={styles.infoContent}>
        <Text type="LegendRegular">
          Export project data as CSV formats and import into any tool of your
          choice for analysis and visualization.
        </Text>
      </div>
    ),
  },
  'Industry template': {
    title: 'Industry template',
    content: (
      <div className={styles.infoContent}>
        <Text type="LegendRegular">How to use industry templates?</Text>
        <Text type="LegendRegular">
          <ol>
            <li>
              Click on 'Copy', and the copied project will appear on your
              projects page.
            </li>
            <li>
              Click on the project card to see “Dashboards“, “Companies”,
              “Roles“ and other options.
            </li>
            <li>
              Click on “Companies“ to add or remove the companies of your
              choice.
            </li>
            <li>
              Roles will automatically be reflected according to predefined
              industry template taxonomy.
            </li>
            <li>Click on “Roles“ if you want to further customize roles.</li>
          </ol>
        </Text>
      </div>
    ),
  },
};

function InfoPopOver({ productName }) {
  return (
    <div className={styles.popOver}>
      <div className={styles.infoTitle}>
        <Text
          type="BodySmallMedium"
          textAlign={'center'}
          style={{ color: 'var(--gray-900)', marginBottom: '10px' }}
        >
          {Infos[productName]?.title}
        </Text>
      </div>
      {Infos[productName]?.content}
    </div>
  );
}

export default InfoPopOver;
