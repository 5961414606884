import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { StateContextProvider } from '@state';
import { BackgroundTasksContextProvider } from '@state/backgroundTasks';
import { AuthContextProvider } from '@state/auth';
import LDProvider from '@state/launchDarkly';
import ErrorBoundary from '@views/app/ErrorBoundary';

function Providers({ children }) {
  const history = createBrowserHistory();
  return (
    <ErrorBoundary>
      <Router history={history}>
        <AuthContextProvider>
          <StateContextProvider>
            <BackgroundTasksContextProvider>
              <LDProvider>{children}</LDProvider>
            </BackgroundTasksContextProvider>
          </StateContextProvider>
        </AuthContextProvider>
      </Router>
    </ErrorBoundary>
  );
}

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
