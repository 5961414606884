import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@components/icon';
import { BasicCheckbox } from '../checkbox';
import styles from './input.module.scss';
import { capitalizeAll } from '@util/string';

export function Input({
  placeholder = 'Enter text...',
  compact = false,
  type = 'text',
  step,
  leftComponent,
  rightComponent,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  value,
  disableClickFocus = false,
  ...rest
}) {
  // textInput must be declared here so the ref can refer to it
  const textInput = useRef(null);

  function handleClick() {
    textInput.current.focus();
  }

  let attributes = {
    type,
    className: styles.textBox,
    placeholder,
    ref: textInput,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    value,
  };

  if (type === 'number') {
    attributes.step = step;
    attributes.min = 0;
    attributes.style = rest?.style;
  }

  return (
    <div
      className={compact ? styles.compactContainer : styles.container}
      onClick={disableClickFocus ? null : handleClick}
      {...rest}
    >
      {leftComponent ? (
        <div className={styles.leftComponent}>{leftComponent}</div>
      ) : null}
      <input {...attributes} />
      {rightComponent ? (
        <div className={styles.rightComponent}>{rightComponent}</div>
      ) : null}
    </div>
  );
}

Input.propTypes = {
  compact: PropTypes.bool,
  disableClickFocus: PropTypes.bool,
  leftComponent: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  rightComponent: PropTypes.node,
  step: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.any,
};

export function InputErrorMessage({ errorMessage }) {
  return <span className={styles.inputError}>{errorMessage}</span>;
}

InputErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

export function SearchInput({ size = 'medium', ...props }) {
  return (
    <Input
      leftComponent={
        <Icon
          icon="search"
          viewBox="0 0 24 24"
          size={size}
          fill="none"
          styles={{ padding: '0px' }}
        />
      }
      {...props}
    />
  );
}

SearchInput.propTypes = {
  size: PropTypes.string,
};

export function AutocompleteInput({
  inputProps,
  multiSelect = true,
  onChange = () => {},
  onSelect = () => {},
  onSelectAndClearRest = () => {},
  toggleSelectAll = () => {},
  suggestions = ['ChoiceA', 'ChoiceB', 'ChoiceC'],
  selectedSuggestions = [],
  placeholder = 'Search...',
  iconSize = 'xsmall',
  categorize,
  toggleCategory,
  capitalizeChoices,
  renameValues,
}) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [userInput, setUserInput] = useState('');
  const timerId = useRef(null);

  const handleInputChange = event => {
    setUserInput(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (hasFocus && userInput.length === 0) {
      setFilteredSuggestions(suggestions);
    } else if (hasFocus) {
      const newFilteredSuggestions = suggestions.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      setFilteredSuggestions(newFilteredSuggestions);
    } else {
      setFilteredSuggestions([]);
    }
  }, [hasFocus, suggestions, userInput]);

  useEffect(() => {
    if (userInput.length === 0) {
      setFilteredSuggestions(suggestions);
    } else {
      const newFilteredSuggestions = suggestions.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setFilteredSuggestions(newFilteredSuggestions);
    }
  }, [userInput, suggestions]);

  function _onClick(suggestion) {
    onSelect(suggestion);
  }

  function _onClickAndClear(suggestion) {
    _onFocus();
    onSelectAndClearRest(suggestion);
  }

  function _onBlur() {
    timerId.current = setTimeout(() => {
      if (hasFocus) {
        setHasFocus(false);
      }
    }, 0);
  }

  function _onFocus() {
    clearTimeout(timerId.current);
    setHasFocus(true);
  }

  // util
  function renderHighlightedSubstring(string, searchString) {
    const firstSplit = string.toLowerCase().indexOf(searchString.toLowerCase());
    const lastSplit = firstSplit !== -1 ? firstSplit + searchString.length : 0;

    if (lastSplit >= 1) {
      return (
        <p className={styles.suggestionParagraph}>
          <span className={styles.unhighlightedSuggestion}>
            {string.substring(0, firstSplit)}
          </span>
          <span className={styles.highlightedSuggestion}>
            {string.substring(firstSplit, lastSplit)}
          </span>
          <span className={styles.unhighlightedSuggestion}>
            {string.substring(lastSplit)}
          </span>
        </p>
      );
    } else {
      return string;
    }
  }

  const sortedSuggestions = filteredSuggestions.sort((a, b) =>
    a.localeCompare(b)
  );

  const isAllSelected = sortedSuggestions.length === selectedSuggestions.length;

  // Categorize suggestions if categorize function exists
  const categorizedSuggestions = categorize && categorize(sortedSuggestions);

  return (
    <div className={styles.autocompleteBackground}>
      <SearchInput
        {...inputProps}
        onChange={handleInputChange}
        compact={false}
        value={userInput}
        onFocus={_onFocus}
        onBlur={_onBlur}
        placeholder={placeholder}
        size={iconSize}
      />
      {hasFocus && sortedSuggestions.length > 0 ? (
        <div>
          <div
            className={styles.selectAll}
            onMouseDown={event => {
              event.preventDefault();
              event.stopPropagation();
              toggleSelectAll();
            }}
          >
            <div>{`${isAllSelected ? 'Deselect' : 'Select'} All`}</div>
            <BasicCheckbox value={isAllSelected} preventDefault={true} />
          </div>
          <div className={styles.suggestions}>
            {categorizedSuggestions
              ? Object.keys(categorizedSuggestions).map(category => (
                  <div key={category}>
                    <div className={styles.suggestion}>
                      <div
                        onMouseDown={event => {
                          event.stopPropagation();
                          toggleCategory(categorizedSuggestions[category]);
                        }}
                        className={styles.categoryLabel}
                      >
                        {category}
                      </div>
                      <div
                        onClick={event => {
                          event.stopPropagation();
                          toggleCategory(categorizedSuggestions[category]);
                        }}
                        onFocus={_onFocus}
                        onBlur={_onBlur}
                        style={{ cursor: 'pointer' }}
                      >
                        {multiSelect ? (
                          <BasicCheckbox
                            value={categorizedSuggestions[
                              category
                            ].every(suggestion =>
                              selectedSuggestions.includes(suggestion)
                            )}
                            preventDefault={true}
                          />
                        ) : null}
                      </div>
                    </div>
                    {categorizedSuggestions[category].map(
                      (suggestion, index) => {
                        const [prefix, suffix] = suggestion.split(' - ');
                        const displayText = suffix || prefix;
                        return (
                          <div
                            key={suggestion}
                            className={styles.suggestion}
                            tabIndex={index}
                          >
                            <div
                              onMouseDown={() => {
                                _onClickAndClear(suggestion);
                              }}
                              className={styles.categorySuggestionLabel}
                            >
                              {renderHighlightedSubstring(
                                displayText,
                                userInput
                              )}
                            </div>
                            <div
                              onClick={() => {
                                _onClick(suggestion);
                              }}
                              onFocus={_onFocus}
                              onBlur={_onBlur}
                              style={{ cursor: 'pointer' }}
                            >
                              {multiSelect ? (
                                <BasicCheckbox
                                  value={selectedSuggestions.includes(
                                    suggestion
                                  )}
                                  preventDefault={true}
                                />
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ))
              : sortedSuggestions.map(suggestion => {
                  let displayText = renameValues?.[suggestion] ?? suggestion;
                  if (capitalizeChoices) {
                    displayText = capitalizeAll(displayText);
                  }
                  return (
                    <div
                      key={suggestion}
                      className={styles.suggestion}
                      tabIndex={0}
                    >
                      <div
                        onMouseDown={() => {
                          _onClickAndClear(suggestion);
                        }}
                        className={styles.suggestionLabel}
                      >
                        {renderHighlightedSubstring(displayText, userInput)}
                      </div>
                      <div
                        onClick={() => {
                          _onClick(suggestion);
                        }}
                        onFocus={_onFocus}
                        onBlur={_onBlur}
                        style={{ cursor: 'pointer' }}
                      >
                        {multiSelect ? (
                          <BasicCheckbox
                            value={selectedSuggestions.includes(suggestion)}
                            preventDefault={true}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

AutocompleteInput.propTypes = {
  iconSize: PropTypes.string,
  inputProps: PropTypes.object,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAndClearRest: PropTypes.func,
  placeholder: PropTypes.string,
  selectedSuggestions: PropTypes.array,
  suggestions: PropTypes.array,
  categorize: PropTypes.func,
};
