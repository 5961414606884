import { BrandLogo } from '@components/brand';
import { Button } from '@components/button';
import { useHistory } from 'react-router';

import { getUserHelpdeskMail } from '../../util/email';
import styles from './styles/AccessDenied.module.scss';

function AccessDenied({ url }) {
  const history = useHistory();
  const message = url.get('error_description');

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <BrandLogo />
          </div>
        </div>
        <div className={styles.main}>
          {message === 'email_verification_required' ? (
            <>
              <p className={styles.title}>
                  Check Your Email for a Verification Link
              </p>
              <div className={styles.message}>
                <p>
                  We've sent a verification link to your email. Please click on the link to verify your email address and complete the signup process.
                </p>
              </div>
            </>
          ) : (
            <>
              <p className={styles.title}>
                Your access to Aura has been denied.
              </p>
              <div className={styles.message}>
                <p>It looks like you don't have an account with us. Please sign up to get started.</p>
                <p>
                  If you continue to experience issues, please reach out to{' '}
                  <a href="">{getUserHelpdeskMail()}</a>.
                </p>
              </div>
            </>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            title={
              message === 'email_verification_required'
                ? 'Try Again'
                : 'Continue to Aura'
            }
            onClick={() => {
              if (message === 'email_verification_required') {
                history.push('/logout');
              } else {
                history.push('/');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AccessDenied;
