import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ProgressBar.module.scss';

export default function ProgressBar({
  percent = 0,
  error = false,
  type = 'default',
  isInfinite = false,
}) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(percent);
  }, [setValue, percent]);

  return (
    <div
      className={classnames(styles.baseContainer, {
        [styles.progressStriped]: type === 'striped' && !error,
        [styles.defaultContainer]: type === 'default',
        [styles.stripedError]: type === 'striped' && error,
      })}
    >
      {(type === 'default' || percent === 100) && (
        <div
          style={{ width: `${value}%` }}
          className={classnames(styles.progress, {
            [styles.infinite]: isInfinite,
            [styles.complete]: percent === 100,
            [styles.error]: error,
          })}
        />
      )}
    </div>
  );
}

ProgressBar.propTypes = {
  error: PropTypes.bool,
  percent: PropTypes.number,
  type: PropTypes.string,
};
