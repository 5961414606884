const buttonSettings = {
  primary: {
    name: 'Primary',
    style: 'primaryButton',
    iconButtonStyle: {
      withoutText: 'primaryIconButtonWithoutText',
      withText: 'primaryIconButtonWithText',
    },
    iconFill: 'var(--white)',
  },
  secondary: {
    name: 'Secondary',
    style: 'secondaryButton',
    iconButtonStyle: {
      withoutText: 'secondaryIconButtonWithoutText',
      withText: 'secondaryIconButtonWithText',
    },
    iconFill: 'var(--violet)',
  },
  ghost: {
    name: 'Ghost',
    style: 'ghostButton',
    iconButtonStyle: {
      withoutText: 'ghostIconButtonWithoutText',
      withText: 'ghostIconButtonWithText',
    },
    iconFill: 'var(--gray-900)',
  },
  iconOnly: {
    iconButtonStyle: {
      withoutText: 'iconOnlyButtonWithoutText',
      withText: 'iconOnlyButtonWithText',
    },
    iconFill: 'var(--gray-500)',
  },
};

export default buttonSettings;
