const safeParseJSON = val => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
};

const _env = {
  ...process.env,
  ...(window && window.process && window.process.env ? window.process.env : {}),
};

export const env = (name, defaultValue, logError = false) => {
  const prefixedName = `REACT_APP_${name}`;

  if (name in _env) {
    return safeParseJSON(_env[name]);
  } else if (prefixedName in _env) {
    return safeParseJSON(_env[prefixedName]);
    // eslint-disable-next-line
  } else if (!!defaultValue) {
    return defaultValue;
  } else {
    if (logError) {
      return `Missing environment variable '${name}'`;
    }

    throw Error(`Missing environment variable '${name}'`);
  }
};
