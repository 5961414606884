import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './text.module.scss';

export default function Text({
  textAlign = 'left',
  type = 'BodySmallRegular',
  className = '',
  style,
  children,
}) {
  return (
    <span
      className={classnames(
        styles[type],
        styles[textAlign + 'Align'],
        className
      )}
      style={style}
    >
      {children}
    </span>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  textAlign: PropTypes.string,
  type: PropTypes.string,
};
